import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { actions } from '../../../modules/reducers';
import { Form } from '../../../components';

const EditAddress = ({ dispatch, history, match, loading, data }) => {
  const { register, errors, handleSubmit } = useForm();
  const { addToast } = useToasts();

  React.useEffect(() => {
    dispatch(actions.fetchAddress(match.params.id));
  }, [dispatch, match.params.id]);

  React.useEffect(() => {
    register('address', { required: true });
  }, [register]);

  const onSubmit = (formValue) => {
    dispatch(
      actions.editAddress(match.params.id, formValue, {
        toast: addToast,
        history,
      }),
    );
  };

  return (
    <Form.Wrapper>
      {!data ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.FormGroup>
            <Form.Label>Nama</Form.Label>
            <Form.Input
              type="text"
              name="name"
              placeholder="Contoh: Toko Cabang Semarang"
              ref={register({ required: true })}
              defaultValue={data.name}
            />
            {errors.name ? (
              <Form.HelperText error>
                Nama alamat tidak boleh kosong
              </Form.HelperText>
            ) : null}
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Label>Nama Penerima</Form.Label>
            <Form.Input
              type="text"
              name="receiver"
              ref={register({ required: true })}
              defaultValue={data.receiver}
            />
            {errors.receiver ? (
              <Form.HelperText error>
                Nama penerima tidak boleh kosong
              </Form.HelperText>
            ) : null}
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Label>No Hp Penerima</Form.Label>
            <Form.Input
              type="text"
              name="phone"
              ref={register({ required: true })}
              defaultValue={data.phone}
            />
            {errors.phone ? (
              <Form.HelperText error>
                No handphone tidak boleh kosong
              </Form.HelperText>
            ) : null}
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Label>Alamat</Form.Label>
            <Form.TextArea
              ref={register({ required: true })}
              name="address"
              placeholder="Contoh: Jl. Raya Pasar Minggu No 1"
              defaultValue={data.address}
            />
            {errors.address ? (
              <Form.HelperText error>Alamat tidak boleh kosong</Form.HelperText>
            ) : null}
          </Form.FormGroup>

          <Form.PrimaryButton type="submit">Simpan Alamat</Form.PrimaryButton>
        </form>
      )}
    </Form.Wrapper>
  );
};

export default connect(({ address }) => {
  return {
    data: address.detail,
    loading: address.loading,
  };
})(EditAddress);
