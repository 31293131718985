import React from 'react';
import s from 'styled-components';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Redirect } from 'react-router-dom';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import { Total } from '../../components';
import ManageAddress from './ManageAddress';
import { actions } from '../../modules/reducers';
import { countQuantity, countUsedLoyaltyPoint } from '../../utils/number';
import { getImage } from '../../utils/image';

const Address = s.div`
    margin-bottom: 16px;

    h3 {
        margin: 0 0 16px;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid #DDD;
    }
`;

const AddressDetail = s.div`
    p {
        font-size: 13px;
        margin: 0 0 4px;

        &:first-child {
            font-weight: 700;
        }

        &:nth-child(2) {
            color: #666;
        }

        &:last-child {
            font-size: 12px;
        }
    }
`;

const AddressAction = s.div`
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;

    button {
        border: 1px solid #000;
        background: #FFF;
        padding: 5px 12px;
        border-radius: 4px;
        margin-right: 8px;
    }
`;

const Items = s.div`
    margin-top: 16px;

    h3 {
        margin: 0 0 16px;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid #DDD;
    }
`;

const Item = s.div`
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 16px;
`;

const Figure = s.figure`
    width: 60px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 8px 0 0;

    img  {
        width: 100%;
        height: auto;
    }
`;

const Meta = s.div`
    p {
        font-size: 13px;
        margin: 0 0 3px;

        &:first-child {
            font-weight: 700;
        }

        &:nth-child(2) {
            color: #666;
            font-size: 12px;
        }
    }
`;

const Menu = s.div`
  margin-bottom: 16px;
  font-size: 12px;

    h3 {
        margin: 0 0 16px;
        font-size: 14px;
        padding-bottom: 8px;
        border-bottom: 1px solid #DDD;
    }
`;

const Select = s.select`
  width: 100%;
  border: 1px solid #000;
  padding: 5px 12px;
  border-radius: 4px;
`;

const LoyaltyPointSwitch = s.div`
  display: flex;
  align-items: center;
  font-size: 14px
`;

function CheckoutPage({ history, dispatch, data, address, courier, user, option }) {
  const { addToast } = useToasts();
  React.useEffect(() => {
    dispatch(actions.fetchCarts());
    dispatch(actions.fetchAddresses());
    dispatch(actions.fetchCouriers());
    dispatch(actions.fetchProfile());
  }, [dispatch]);

  const [addr, switchAddress] = React.useState(null);
  const [deliveryType, setDeliveryType] = React.useState('DELIVERY');
  const [payment, setPayment] = React.useState('BANK_TRANSFER');
  const [addAddress, showAddAddress] = React.useState(false);
  const [changeAddress, showAllAddress] = React.useState(false);
  const [chosenCourier, setCourier] = React.useState(1);
  const [loyaltyPoint, setLoyaltyPoint] = React.useState(0);
  const [useLoyaltyPoint, setUseLoyaltyPoint] = React.useState(false);

  const total = data.reduce((total, item) => {
    total += item.product.price * item.qty;
    return total;
  }, 0)

  React.useEffect(() => {
    if (address.length && !addr) {
      const defaultAddr = address.find((addr) => addr.set_default);
      switchAddress(defaultAddr);
    }
  }, [addr, address]);

  const handleOrder = () => {
    const data = {
      address_id: addr.id,
      delivery_type: deliveryType,
      payment: payment,
      courier_id: chosenCourier, // @todo: marvel, nanti ambil courier dari API
      is_use_point: useLoyaltyPoint,
    };
    dispatch(actions.createOrder(data, { toast: addToast, history }));
    
    setUseLoyaltyPoint(false);
  };

  const handleCloseAddress = () => {
    showAddAddress(false);
    showAllAddress(false);
  };

  const handleSelectAddress = (addressID) => {
    handleCloseAddress();
    switchAddress(addressID);
  };

  const handleLoyaltyPointChecked = (checked) => {
    if (user?.loyaltyPoint?.balance > 0) {
      const usedLoyaltyPointsToCurrency = countUsedLoyaltyPoint(total, parseInt(option.point_currency));

      setLoyaltyPoint(checked ? usedLoyaltyPointsToCurrency : 0);
      setUseLoyaltyPoint(checked);
    }
  }

  const renderLoyaltyPoint = () => {
    const loyaltyPointsToCurrency = user?.loyaltyPoint?.balance * parseInt(option?.point_currency);
    const totalToPoint = total / parseInt(option?.point_currency);
  
    return (
      <div>
        <p style={{ margin: 0, marginTop: '10px', fontSize: '14px' }}>
          Total Loyalty point anda: {`${user?.loyaltyPoint?.balance} (Rp. ${loyaltyPointsToCurrency.toLocaleString('id')})`}
        </p>

        {
          useLoyaltyPoint && (
            <p style={{ margin: 0, marginTop: '5px', fontSize: '14px', color: '#ff4d4f' }}>
              Point yang akan digunakan: {`${totalToPoint} (Rp. ${total.toLocaleString('id')})`}
            </p>
          )
        }
      </div>
    )
  }

  if (data.length === 0) {
    return <Redirect to="/keranjang" />;
  }

  return (
    <div
      style={{
        margin: '48px auto 80px',
        padding: 16,
        maxWidth: 550,
      }}
    >
      <Items>
        <h3>Barang</h3>
        {data.map((item) => {
          const picture = JSON.parse(item.product.pictures);
          return (
            <Item key={item.id}>
              <Figure>
                <img
                  src={getImage(picture[0], 100, '3:4')}
                  alt={item.product.name}
                />
              </Figure>
              <Meta>
                <p>{item.product.name}</p>
                <p>{countQuantity(item.qty)}</p>
                <p>Rp. {item.product.price.toLocaleString('id')}</p>
              </Meta>
            </Item>
          );
        })}
      </Items>
      <Menu>
        <h3>Pengiriman</h3>
        <RadioGroup
          onChange={(value) => {
            setDeliveryType(value);
          }}
          value={deliveryType}
          horizontal
        >
          <RadioButton
            rootColor="black"
            pointColor="darkred"
            iconSize={20}
            innerIconSize={40}
            padding={8}
            value="DELIVERY"
          >
            Kirim
          </RadioButton>
          <RadioButton
            rootColor="black"
            pointColor="darkred"
            iconSize={20}
            innerIconSize={40}
            padding={8}
            value="PICKUP"
          >
            Ambil di Toko
          </RadioButton>
        </RadioGroup>
      </Menu>
      {deliveryType === 'DELIVERY' ? (
        <>
          <Address>
            <h3>Pilih Alamat</h3>
            {addr ? (
              <AddressDetail>
                <p>
                  {addr.receiver} ({addr.name})
                </p>
                <p>{addr.phone}</p>
                <p>
                  {addr.addr} {addr.village}, {addr.district}, {addr.regency},{' '}
                  {addr.province}{' '}
                  {addr.postal_code ? `, ${addr.postal_code}` : ''}
                </p>
              </AddressDetail>
            ) : null}
            {!addr ? (
              <span style={{ color: 'red', fontSize: 12 }}>
                Alamat tidak boleh kosong
              </span>
            ) : null}
            <AddressAction>
              <button onClick={() => showAddAddress(true)}>
                Tambah Alamat
              </button>
              <button
                onClick={() => showAllAddress(true)}
                disabled={address.length <= 1}
              >
                Ganti Alamat
              </button>
            </AddressAction>
          </Address>
          <Menu>
            <h3>Kurir</h3>
            <Select
              onChange={(event) => setCourier(event.target.value)}
              value={chosenCourier}
            >
              {courier.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
          </Menu>
        </>
      ) : null}
      <Menu>
        <h3>Pembayaran</h3>
        <RadioGroup
          onChange={(value) => {
            setPayment(value);
          }}
          value={payment}
          horizontal
        >
          <RadioButton
            rootColor="black"
            pointColor="darkred"
            iconSize={20}
            innerIconSize={40}
            padding={8}
            value="BANK_TRANSFER"
          >
            Transfer Bank
          </RadioButton>
          <RadioButton
            rootColor="black"
            pointColor="darkred"
            iconSize={20}
            innerIconSize={40}
            padding={8}
            value="CASH"
          >
            Cash
          </RadioButton>
        </RadioGroup>
      </Menu>
      <div>
        <div>
          <LoyaltyPointSwitch>
            <Toggle
              checked={useLoyaltyPoint}
              onChange={() => handleLoyaltyPointChecked(!useLoyaltyPoint)}
              disabled={user?.loyaltyPoint?.balance < 1}
            />
            <p style={{ margin: 0, marginLeft: '10px', fontSize: '14px' }}>Bayar menggunakan loyalty point</p>
          </LoyaltyPointSwitch>

          {
            user?.loyaltyPoint?.balance > 0 ? (
              renderLoyaltyPoint()
            ) : (
              <p style={{ margin: 0, marginTop: '10px', color: '#ff4d4f', fontSize: '14px' }}>Anda tidak memiliki loyalty point!</p>
            )
          }
        </div>
        <Total
          buttonTitle="Bayar"
          checkoutDisabled={!addr}
          onCheckout={handleOrder}
          total={total - loyaltyPoint}
        />
      </div>
      <ManageAddress
        dispatch={dispatch}
        addAddress={addAddress}
        changeAddress={changeAddress}
        onChange={handleSelectAddress}
        onClose={handleCloseAddress}
        address={address}
      />
    </div>
  );
}

export default connect(({ cart, address, courier, user }) => {
  return {
    data: cart.data,
    loading: cart.loading,
    address: address.data,
    courier: courier.data,
    user: user.detail,
    option: user.options.reduce((obj, data) => {
      obj[data.key] = data.value;
      return obj;
    }, {}),
  };
})(CheckoutPage);
