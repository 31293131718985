import s from 'styled-components';

export const Container = s.div`
  margin: 60px auto 80px;
  padding: 0 16px;
  max-width: 550px;
  min-height: 100vh;
`;

export const ModelWrapper = s.div`
  display: flex;
  flex-flow: row wrap;
`;

export const Category = s.div`
  width: 25%;
  margin-bottom: 16px;

  figure {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
    }
  }

  a {
    text-decoration: none;
    color: #111;
    font-size: 10px;
    display: block;
    text-align: center;
  }
`;

export const Name = s.span`
  display: block;
  margin-top: 4px;
`;
