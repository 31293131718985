import React from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { Cart, Total } from '../../components';
import { actions } from '../../modules/reducers';

function CartPage({ history, dispatch, data }) {
  const { addToast } = useToasts();
  React.useEffect(() => {
    dispatch(actions.fetchCarts());
  }, [dispatch]);

  return (
    <div
      style={{
        margin: '48px auto 80px',
        padding: 16,
        maxWidth: 550,
      }}
    >
      <Cart
        updateCart={(cartID, qty) => {
          dispatch(actions.updateCart(cartID, qty, { toast: addToast }));
        }}
        data={data.map((cart) => {
          return {
            id: cart.id,
            name: cart.product.name,
            picture: cart.product.pictures
              ? JSON.parse(cart.product.pictures)[0]
              : '',
            price: cart.product.price,
            qty: cart.qty,
            productQty: cart.product.stock_qty,
          };
        })}
      />

      {data.length ? (
        <Total
          history={history}
          total={data.reduce((total, item) => {
            total += item.product.price * item.qty;
            return total;
          }, 0)}
        />
      ) : null}
    </div>
  );
}

export default connect(({ cart }) => {
  return {
    data: cart.data,
    loading: cart.loading,
  };
})(CartPage);
