export const models = [
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    name: '',
    picture: '',
    items: [1, 2, 3, 4, 5, 6, 7, 8],
  },
];
