const DOZEN = 12;

export const countQuantity = (count) => {
  const pcs = count % 12 !== 0 ? `${count % 12} pcs` : '';
  return count < DOZEN
    ? `${count} pcs.`
    : `${Math.floor(count / DOZEN)} lusin ${pcs}`;
};

export const countUsedLoyaltyPoint = (total, pointCurrency) => {
  const pointCurrencyValue = Math.floor(total / pointCurrency);
  const usedLoyaltyPointsToCurrency = pointCurrencyValue * pointCurrency;

  return usedLoyaltyPointsToCurrency;
};

export const countDozen = (count) => {
  return count < DOZEN ? 0 : Math.floor(count / DOZEN);
}

export const intToString = (value) => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};
