import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import s from 'styled-components';

const Card = s.div`
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border: 1px solid #EEE;
  margin-bottom: 16px;
`;

const Header = s.div`
  padding: 8px 16px 0;
  position: relative;
  
  span {
    font-size: 12px;
    margin-right: 4px;
    display: inline-block;
    color: #888;
  }

  small {
    position: absolute;
    top: 10px;
    right: 16px;
    background: darkred;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    margin-right: 4px;
  }
`;

const Content = s.div`
  padding: 0 16px 8px;
  
  span {
    display: block;
    font-size: 13px;
    color: #666;
    margin-bottom: 4px;

    &:first-child {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

const Footer = s.div`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #EEE;

  a, button {
    text-decoration: none;
    display: block;
    padding: 4px 8px;
    text-align: center;
    font-size: 10px;
    border: 1px solid darkred;
    color: darkred;
    font-weight: 600;
    border-radius: 4px;
    background: #FFF;

    &:nth-child(2) {
      margin: 0 4px;
    }

    &:last-child {
      background: darkred;
      color: #FFF;
    }
  }
`;

const AddressCard = (props) => {
  return (
    <Card>
      <Header>
        <span>{props.name}</span>
        {props.set_default ? <small>Alamat Utama</small> : null}
      </Header>
      <Content>
        <span>{props.receiver}</span>
        <span>{props.phone}</span>
        <span style={{ fontSize: 11 }}>
          {props.address}, {get(props, 'village', '')},{' '}
          {get(props, 'district', '')}, {get(props, 'regency', '')},{' '}
          {get(props, 'province', '')} {props.postal_code}
        </span>
      </Content>
      <Footer>
        <Link to={`/pengaturan/alamat/${props.id}`}>Ubah Alamat</Link>
        <button onClick={() => props.setAsDefault(props.id)}>
          Jadikan Alamat Utama
        </button>
        <button onClick={() => props.deleteConfirmation(props.id)}>
          <i className="fa fa-trash" />
        </button>
      </Footer>
    </Card>
  );
};

export default AddressCard;
