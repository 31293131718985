import React from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { Table, Item, Figure, Meta, Name, Price, Quantity } from './helpers';
import { countQuantity } from '../../utils/number';
import { getImage } from '../../utils/image';

const CartItem = ({ item, updateCart, totalStock }) => {
  const [qty, setQty] = React.useState(item.qty);
  const didMountRef = React.useRef(false);

  const throttled = React.useRef(
    debounce((itemID, qty) => {
      updateCart(itemID, qty);
    }, 1000),
  );

  React.useEffect(() => {
    if (didMountRef.current) {
      throttled.current(item.id, qty);
    } else {
      didMountRef.current = true;
    }
  }, [item.id, qty]);

  return (
    <tr key={item.id}>
      <td>
        <Item>
          <Figure>
            <img src={getImage(item.picture)} alt={item.name} />
          </Figure>
          <Meta>
            <Name>{item.name}</Name>
            <Price>Rp. {item.price.toLocaleString('id')}</Price>
            <Quantity>
              <button
                onClick={() => {
                  setQty(0);
                }}
                style={{ marginRight: 16 }}
              >
                <i className="fa fa-trash" />
              </button>

              <button
                disabled={qty === 4}
                onClick={() => {
                  setQty(qty - 4);
                }}
              >
                <i className="fa fa-minus" />
              </button>
              <input
                readOnly
                type="text"
                value={countQuantity(qty)}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setQty(4);
                  } else {
                    setQty(e.target.value);
                  }
                }}
              />
              <button
                disabled={qty >= totalStock}
                onClick={() => {
                  setQty(qty + 4);
                }}
              >
                <i className="fa fa-plus" />
              </button>
            </Quantity>
          </Meta>
        </Item>
      </td>
    </tr>
  );
};

const CartItems = (props) => {
  if (props.data.length === 0) {
    return (
      <div>
        <h3 style={{ textAlign: 'center', fontSize: 14 }}>
          Keranjang masih kosong
        </h3>
        <p style={{ fontSize: 12, color: '#666', textAlign: 'center' }}>
          <Link
            style={{ color: 'darkred', textDecoration: 'none' }}
            to="/eksplor"
          >
            Mulai berbelanja?
          </Link>
        </p>
      </div>
    );
  }

  return (
    <Table border={1}>
      <thead>
        <tr>
          <th align="left">Item</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item) => {
          return (
            <CartItem
              key={item.id}
              item={item}
              updateCart={props.updateCart}
              totalStock={item.qty + item.productQty}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default CartItems;
