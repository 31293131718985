import React from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import s from 'styled-components';
import { getImage } from '../../../utils/image';

const Header = s.h3`
  margin: 0 0 16px 16px;
  font-size: 14px;
  text-transform: uppercase;
`;

const ModelWrapper = s.div`
  display: flex;
  flex-flow: row wrap;
`;

const Category = s.div`
  width: 25%;
  margin-bottom: 16px;

  figure {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
    }
  }

  a {
    text-decoration: none;
    color: #111;
    font-size: 10px;
    display: block;
    text-align: center;
  }
`;

const Name = s.span`
  display: block;
  margin-top: 4px;
`;

const SeeAll = s.span`
  display: block;
  background: #EEE;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;

  i {
    padding-top: 14px;
    font-size: 22px;
    color: #333;
  }
`;

const ItemLoader = () => (
  <Category>
    <span
      style={{
        display: 'block',
        background: '#DDD',
        borderRadius: '50%',
        width: 50,
        height: 50,
        margin: '0 auto',
      }}
    ></span>
    <span
      style={{
        fontSize: 10,
        display: 'block',
        textAlign: 'center',
        marginTop: 4,
        width: 50,
        height: 10,
        margin: '4px auto 0',
        background: '#DDD',
      }}
    ></span>
  </Category>
);

function Model({ loading, data }) {
  return (
    <div style={{ marginBottom: 16 }}>
      <Header>Kategori</Header>
      <ModelWrapper>
        {loading
          ? [...Array(8).keys()].map((m) => {
              return <ItemLoader key={m} />;
            })
          : null}
        {data.map((category) => {
          return (
            <Category key={category.id}>
              <Link to={`/kategori/${category.slug}`}>
                <figure>
                  <img
                    src={getImage(category.picture, 100, '1:1')}
                    alt={category.name}
                  />
                </figure>
                <Name>
                  <Truncate lines={1} ellipsis="...">
                    {category.name}
                  </Truncate>
                </Name>
              </Link>
            </Category>
          );
        })}
        {!loading ? (
          <Category key={999}>
            <Link to="/kategori">
              <SeeAll>
                <i className="fa fa-th"></i>
              </SeeAll>
              <Name>Lihat Semua</Name>
            </Link>
          </Category>
        ) : null}
      </ModelWrapper>
    </div>
  );
}

export default Model;
