import React from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

import { Wrapper, Slide, SlideLoader, ShareAction, Button } from './helpers';
import { getImage } from '../../utils/image';
import { getOS } from '../../utils/device';

const toBlob = (src) =>
  new Promise((res) => {
    const img = document.createElement('img');
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    img.onload = ({ target }) => {
      c.width = target.naturalWidth;
      c.height = target.naturalHeight;
      ctx.drawImage(target, 0, 0);
      c.toBlob((b) => res(b), 'image/jpeg', 0.75);
    };
    img.crossOrigin = '';
    img.src = src;
  });

const save = (blob, name = 'image.png') => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.target = '_blank';
  a.download = name;
  a.click();
};

const SliderLightbox = ({ data, loading }) => {
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  let pictures = [];
  try {
    pictures = JSON.parse(data);
  } catch (error) {}

  const settings = {
    dots: true,
    swipeToSlide: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      if (photoIndex !== index) {
        setPhotoIndex(index);
      }
    },
  };

  // Browser still not suportted this feature
  //
  // const handleShare = React.useCallback(async () => {
  //   try {
  //     const image = await toBlob(pictures[photoIndex]);

  //     if (navigator.share) {
  //       navigator.share({
  //         files: [image],
  //         text: 'Share',
  //         url: 'https://www.hello-pgmta.com',
  //       });
  //     } else {
  //       alert('Share image not supported');
  //     }
  //   } catch (error) {
  //     alert(error);
  //   }
  // }, [photoIndex, pictures]);

  const handleDownload = React.useCallback(
    async (e) => {
      e.preventDefault();

      if (getOS() === 'Android') {
        try {
          const fileName = pictures[photoIndex].substring(
            pictures[photoIndex].lastIndexOf('/') + 1,
          );

          save(await toBlob(pictures[photoIndex]), fileName);
        } catch (error) {
          alert(error);
        }
      } else {
        window.open(pictures[photoIndex], '_blank');
      }
    },
    [photoIndex, pictures],
  );

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper>
        <Slider {...settings}>
          {loading ? (
            <div>
              <SlideLoader />
            </div>
          ) : null}
          {pictures.map((slide, idx) => {
            return (
              <div key={idx}>
                <Slide
                  data-slideindex={idx}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <img src={getImage(slide, 550)} alt="product" />
                </Slide>
              </div>
            );
          })}
        </Slider>
        {open && (
          <Lightbox
            mainSrc={getImage(pictures[photoIndex], 550, '3:4')}
            nextSrc={getImage(
              pictures[(photoIndex + 1) % pictures.length],
              550,
              '3:4',
            )}
            prevSrc={getImage(
              pictures[(photoIndex + pictures.length - 1) % pictures.length],
              550,
              '3:4',
            )}
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + pictures.length - 1) % pictures.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % pictures.length)
            }
          />
        )}
      </Wrapper>
      <ShareAction>
        {/* <Button onClick={handleShare}>
          <i className="fa fa-share-alt" />
        </Button> */}
        <Button onClick={handleDownload}>
          <i className="fa fa-download" />
        </Button>
      </ShareAction>
    </div>
  );
};

export default SliderLightbox;
