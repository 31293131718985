const spacesURL = (url, width) => {
  const baseURL = 'https://hellopgmta.sgp1.digitaloceanspaces.com';

  const sizeMapper = {
    50: 100,
    60: 100,
    200: 200,
    480: 550,
    550: 550,
  };

  const size = sizeMapper.hasOwnProperty(width) ? sizeMapper[width] : 550;

  let newURL = url;
  if (url.indexOf(baseURL) > -1) {
    const firstPart = url.substr(0, url.length - 4);
    const lastPart = url.substr(firstPart.length);

    newURL = firstPart.replace(baseURL, 'https://static.hello-pgmta.com') + '_' + size + lastPart;
  }

  return newURL;
};

export const getImage = (url, width = 480, ratio = '1:1') => {
  if (!url) {
    return '';
  }

  const optimization = `/fl_progressive,fl_lossy,c_fill,g_face,q_auto:best,w_${width},ar_${ratio}`;
  const baseURL = 'https://res.cloudinary.com/hello-pgmta/image/upload';

  const newURL =
    url.indexOf(baseURL) > -1
      ? url.substr(0, baseURL.length) +
        optimization +
        url.substr(baseURL.length)
      : spacesURL(url, width);

  return newURL;
};
