import * as c from './constants';

export const initialState = {
  loading: false,
  checkoutLoading: false,
  data: [],
  count: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.CART_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case c.CHECKOUT_LOADING:
      return {
        ...state,
        checkoutLoading: action.loading,
      };
    case c.CARTS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case c.CARTS_COUNT_FULFILLED:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};

export default cartReducer;
