import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { createGlobalStyle } from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './App';
import configureStore from './modules/store';
import * as serviceWorker from './serviceWorker';

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  * {
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
  }
`;

const store = configureStore();

const Container = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="top-right"
      >
        <App />
      </ToastProvider>
    </Provider>
  );
};

ReactDOM.render(<Container />, document.getElementById('root'));
serviceWorker.unregister();
