import s from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = s.ul`
    margin: 48px auto 0;
    padding: 16px;
    max-width: 550px;
`;

export const CreateAddressButton = s(Link)`
    padding: 8px;;
    border: 1px solid darkred;
    border-radius: 4px;
    background: #FFF;
    text-decoration: none;
    width: 100%;
    color: darkred;
    display: block;
    margin: 0 0 16px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 700;

    i {
        margin-right: 8px;
    }
`;
