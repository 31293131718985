import * as c from './constants';
import api from './../../../utils/api';

export const fetchOrders = (page = 1) => {
  return async (dispatch) => {
    dispatch({
      type: c.ORDER_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get('/order/me?page=' + page);
      if (res.success) {
        dispatch({
          type: c.ORDERS_FULFILLED,
          payload: res.data,
          pagination: res.pagination,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: c.ORDER_LOADING,
        loading: false,
      });
    }
  };
};

export const fetchOrder = (orderID) => {
  return async (dispatch) => {
    dispatch({
      type: c.ORDER_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get(`/order/${orderID}/detail`);
      if (res.success) {
        dispatch({
          type: c.ORDER_FULFILLED,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: c.ORDER_LOADING,
        loading: false,
      });
    }
  };
};

export const cancelOrder = (orderID, { toast }) => {
  return async (dispatch) => {
    try {
      await api.put(`/order/${orderID}/cancel`, {
        status: 'CANCELLED',
      });
      dispatch(fetchOrder(orderID));
      toast('Order berhasil dibatalkan', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      toast('Terjadi kesalahan: ' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
};
