import s from 'styled-components';

export const Wrapper = s.div`
  margin-top: 40px;

  .slick-dots {
    bottom: 20px;

    li button:before {
      color: #FFF;
      font-size: 10px;
    }
  }
`;

export const Slide = s.div`
  width: 100%;
  max-height: 375px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
`;

export const SlideLoader = s.div`
  width: 100%;
  height: 375px;
  background: #EEE;
`;

export const ShareAction = s.div`
  position: absolute;
  bottom: -50px;
  right: 16px;
`;

export const Button = s.button`
  padding: 10px 16px;
  border: none;
  background: darkred;
  color: #FFF;
  border-radius: 4px;
  margin-left: 4px;
`;
