import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import s from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import { Wrapper, Loader } from './helpers';
import { actions } from '../../../modules/reducers';
import { statusOrderMapper } from './utils';
import { countQuantity } from '../../../utils/number';
import { getImage } from '../../../utils/image';

const Order = s(Link)`
  display: block;
  text-decoration: none;
  color: #111;
  margin: 0 16px 0;
  border: 1px solid #EFEFEF;
  border-radius: 4px 4px 0 0;
`;

const Status = s.div`
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  background: #DDD;
  padding: 8px 16px;
  border-radius: 4px 4px 0 0;
`;

const Meta = s.div`
  border-bottom: 1px solid #EEE;
  padding: 8px 16px;

  p {
    font-size: 12px;
    margin: 0 0 4px;

    &:first-child {
      color: #777;
    }
  }
`;

const Items = s.div`
  padding: 16px 16px 0;
`;

const Item = s.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 16px;
`;

const Figure = s.div`
  width: 70px;
  overflow: hidden;
  margin-right: 16px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Desc = s.div`
  p {
    font-size: 11px;
    color: #666;
    margin: 0;
  }
`;

const Name = s.div`
  font-size: 14px;
  font-weight: bold;
`;

const Footer = s.div`
  font-weight: bold;
  color: darkred;
  padding: 8px 16px;
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
  text-align: right;
`;

const BuyAgain = s.button`
  display: block;
  width: 100%;
  background: darkred;
  color: #FFF;
  border: none;
  border-radius: 0 0 4px 4px;
  padding: 12px;
  text-align: center;
`;

const OrderList = ({ dispatch, data, loading, history }) => {
  React.useEffect(() => {
    dispatch(actions.fetchOrders());
  }, [dispatch]);

  const { addToast } = useToasts();

  const handleBuyAgain = (data) => {
    dispatch(actions.buyAgain(data, { toast: addToast, history }));
  };

  return (
    <Wrapper style={{ paddingTop: 16 }}>
      {loading ? (
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Loader height={200} />
          <Loader height={200} />
        </div>
      ) : null}
      {data.length === 0 ? (
        <div style={{ paddingLeft: 16, paddingRight: 16, textAlign: 'center' }}>
          Belum ada order
        </div>
      ) : null}
      {data.map((order) => {
        return (
          <React.Fragment key={order.id}>
            <Order key={order.id} to={`/pengaturan/order/${order.id}`}>
              <Status>{statusOrderMapper[order.status]}</Status>
              <Meta>
                <p>{new Date(order.created_at).toLocaleString('id')}</p>
                <p>{order.invoice_no}</p>
              </Meta>
              <Items>
                {order.items.map((item) => {
                  let images = '';
                  try {
                    images = JSON.parse(item.picture);
                  } catch (err) {}
                  return (
                    <Item key={item.id}>
                      <Figure>
                        <img
                          src={getImage(images[0], 100, '1:1')}
                          alt={item.name}
                        />
                      </Figure>
                      <Desc>
                        <Name>{item.name}</Name>
                        <p>{countQuantity(item.quantity)}</p>
                      </Desc>
                    </Item>
                  );
                })}
              </Items>
              <Footer>Total: Rp. {order.total.toLocaleString('id')}</Footer>
              <div
                style={{
                  padding: 10,
                  textAlign: 'center',
                  fontSize: 13,
                  background: '#EEE',
                }}
              >
                Lihat Detail
              </div>
            </Order>
            <div style={{ padding: '0 16px 16px' }}>
              <BuyAgain
                onClick={() => {
                  const items = order.items.map((item) => ({
                    product_id: item.product_id,
                    qty: item.quantity,
                  }));

                  handleBuyAgain(items);
                }}
              >
                Beli Lagi
              </BuyAgain>
            </div>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

export default connect(({ order }, { location }) => {
  const qs = new URLSearchParams(location.search);
  return {
    data: order.data,
    loading: order.loading,
    qs: qs,
  };
})(OrderList);
