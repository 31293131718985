import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { actions } from './../../modules/reducers';
import { Wrapper, FormGroup, Input, Button, Label } from './components/helpers';

const Login = ({ dispatch, history }) => {
  const { addToast } = useToasts();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(actions.login(data, { history, toast: addToast }));
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="phoneNumber" style={{ display: 'inline-block' }}>
            No Handphone
          </Label>
          <Input
            type="number"
            placeholder="08123456789"
            name="phone"
            ref={register({ required: true })}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password" style={{ marginRight: '10px' }}>
            Password
          </Label>
          <Input
            type="password"
            placeholder="Password"
            name="password"
            ref={register({ required: true })}
          />
        </FormGroup>
        <FormGroup>
          <Button type="submit">Login</Button>
        </FormGroup>
        <p>
          Belum punya akun ? <Link to="/register">Daftar</Link>
        </p>
      </form>
    </Wrapper>
  );
};

export default connect()(Login);
