import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Container,
  HomeSearch,
  HomeOuter,
  Wrapper,
  NormalOuter,
  NormalTitle,
  AuthButton,
  Button,
  BackButton,
  SearchWrapper,
  InputSearch,
  SearchReset,
  SearchButton,
} from './helpers';
import { actions } from '../../modules/reducers';

const Home = (props) => {
  return (
    <HomeOuter>
      <HomeSearch>
        <Link to="/eksplor">Cari apa di Hello Collection?</Link>
      </HomeSearch>

      <AuthButton>
        <Button to="/keranjang">
          <i className="fa fa-shopping-cart" />
          {props.cartCount ? (
            <span className="cart-count">{props.cartCount}</span>
          ) : null}
        </Button>
      </AuthButton>
    </HomeOuter>
  );
};

const routeName = {
  '/eksplor': 'Terbaru',
  '/pengaturan': 'Pengaturan',
  '/diskon': 'Diskon',
  '/keranjang': 'Keranjang',
  '/checkout': 'Belanja',
  '/kategori': 'Semua Kategori',
  '/pengaturan/profil': 'Ubah Data Pribadi',
  '/pengaturan/alamat': 'Alamat',
  '/pengaturan/order': 'Histori Order',
  '/login': 'Masuk',
  '/register': 'Daftar',
};

const Normal = (props) => {
  const history = useHistory();
  const location = useLocation();

  const showWhitelist = [/^(\/produk)\/[A-Za-z0-9]+/];
  const isShow = showWhitelist.some((x) => x.test(location.pathname));

  return (
    <NormalOuter>
      {props.backButton ? (
        <div>
          <BackButton onClick={() => history.goBack()}>
            <i className="fa fa-chevron-left" />
          </BackButton>
        </div>
      ) : null}
      <NormalTitle backButton={props.backButton}>
        <h2>
          {routeName.hasOwnProperty(location.pathname)
            ? routeName[location.pathname]
            : 'Hello Collection'}
        </h2>
      </NormalTitle>
      {isShow ? (
        <div
          style={{
            marginTop: -6,
            marginRight: 20,
          }}
        >
          <Button to="/keranjang">
            <i className="fa fa-shopping-cart" />
            {props.cartCount ? (
              <span className="cart-count">{props.cartCount}</span>
            ) : null}
          </Button>
        </div>
      ) : null}
    </NormalOuter>
  );
};

const Search = ({ dispatch, search }) => {
  const history = useHistory();
  const [name, setName] = React.useState('');

  return (
    <NormalOuter>
      <SearchWrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(actions.exploreReset(true));
            dispatch(actions.exploreSearch({ query: name, page: 1 }));
            history.push(`/eksplor?q=${name}`);
          }}
        >
          <InputSearch
            type="text"
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Cari motif..."
            defaultValue={search.query || ''}
          />
          <SearchReset
            onClick={() => {
              dispatch(actions.exploreReset(!!search.query));
              setName('');
              history.push('/eksplor');
            }}
            type="reset"
          >
            <i className="fa fa-close" />
          </SearchReset>
          <SearchButton type="submit">Cari</SearchButton>
        </form>
      </SearchWrapper>
    </NormalOuter>
  );
};

const renderChild = (type, props) => {
  switch (type) {
    case 'HOME':
      return <Home {...props} />;

    case 'SEARCH':
      return <Search {...props} />;

    case 'CHECKOUT':
      return <Normal backButton {...props} />;

    case 'LOGIN':
    case 'REGISTER':
    case 'CATEGORY':
    case 'PRODUCT':
    case 'SETTING':
      return <Normal backButton {...props} />;

    default:
      return <Normal {...props} />;
  }
};

const Header = (props) => {
  const location = useLocation();
  let headerType = 'DEFAULT';
  if (location.pathname === '/') headerType = 'HOME';
  if (location.pathname === '/eksplor') headerType = 'SEARCH';
  if (location.pathname === '/checkout') headerType = 'CHECKOUT';
  if (location.pathname === '/login') headerType = 'LOGIN';
  if (location.pathname === '/register') headerType = 'REGISTER';
  if (location.pathname.indexOf('/kategori') > -1) headerType = 'CATEGORY';
  if (location.pathname.indexOf('/produk') > -1) headerType = 'PRODUCT';
  if (location.pathname.indexOf('/pengaturan/') > -1) headerType = 'SETTING';

  return (
    <Container>
      <Wrapper>{renderChild(headerType, props)}</Wrapper>
    </Container>
  );
};

export default connect(({ explore }) => {
  return {
    search: explore.search,
  };
})(Header);
