import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  LineShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LineIcon,
  WhatsappIcon,
  TelegramIcon,
} from 'react-share';
import { useToasts } from 'react-toast-notifications';
import { useCookies } from 'react-cookie';

import {
  Container,
  Card,
  Price,
  Name,
  Subtitle,
  Description,
  Info,
  Actions,
  Share,
  Cart,
  ModalContainer,
  ModalWrapper,
  ModalTitle,
  ShareContent,
  QuantityContent,
  SelectQuantityModalWrapper,
  ModalFooter,
} from './helpers';
import { SliderLightbox, Modal, Carousel, ProductCard } from '../../components';
import { actions } from '../../modules/reducers';
import { countDozen } from '../../utils/number';

const shareURL = (slug) =>
  `https://hello-pgmta.com/produk/${slug}?utm_source=pdp-share`;

function ProductDetail({
  match,
  history,
  dispatch,
  loading,
  data,
  related,
  categoryRelated,
}) {
  const [share, setShare] = React.useState(false);
  const [showQuantityModal, setShowQuantityModal] = React.useState(false);
  const [pcs, setPcs] = React.useState(4);
  const [dozen, setDozen] = React.useState(0);
  const [quantity, setQuantity] = React.useState(pcs);
  const { addToast } = useToasts();

  const [cookies] = useCookies(['access_token']);

  React.useEffect(() => {
    dispatch(actions.fetchProduct(match.params.slug));
    dispatch(actions.fetchRelatedProduct(match.params.slug));
    dispatch(actions.fetchCategoryRelatedProduct(match.params.slug));
  }, [dispatch, match.params.slug]);

  return (
    <div style={{ marginTop: 40, maxWidth: 550, margin: '0 auto' }}>
      {data ? <SliderLightbox loading={loading} data={data.pictures} /> : null}
      {data ? (
        <Container>
          <Card>
            <Price>Rp. {parseInt(data.price).toLocaleString('id')}</Price>
            <Name>{data.name}</Name>
          </Card>
          {data.description || data.category.description ? (
            <Card>
              <Subtitle>Deskripsi</Subtitle>
              <Description>
                {data.description || data.category.description}
              </Description>
            </Card>
          ) : null}
          <Card>
            <Subtitle>Info</Subtitle>
            <Info>
              <div>Model</div>
              <div>
                <Link to={`/kategori/${data.category.slug}`}>
                  {data.category.name}
                </Link>
              </div>

              <div>Stok</div>
              <div>{data.stock_qty} pcs.</div>

              <div>Dilihat</div>
              <div>{data.view_count} kali.</div>
            </Info>
          </Card>
          {categoryRelated.length !== 0 ? (
            <>
              <div style={{ margin: '16px' }}>
                <Subtitle>Motif Lainnya</Subtitle>
              </div>
              <Carousel>
                {categoryRelated.map((r, idx) => {
                  let pictures = [];
                  try {
                    pictures = JSON.parse(r.pictures);
                  } catch (err) {}

                  return (
                    <ProductCard
                      key={r.id}
                      carousel
                      firstItem={idx === 0}
                      name={r.name}
                      price={r.price}
                      picture={pictures[0]}
                      slug={r.slug}
                      count={r.stock_qty}
                      sold_count={r.sold_count}
                    />
                  );
                })}
              </Carousel>
            </>
          ) : null}
          {related.length !== 0 ? (
            <>
              <div style={{ margin: '16px' }}>
                <Subtitle>Mungkin anda suka</Subtitle>
              </div>
              <Carousel>
                {related.map((r, idx) => {
                  let pictures = [];
                  try {
                    pictures = JSON.parse(r.pictures);
                  } catch (err) {}

                  return (
                    <ProductCard
                      key={r.id}
                      carousel
                      firstItem={idx === 0}
                      name={r.name}
                      price={r.price}
                      picture={pictures[0]}
                      slug={r.slug}
                      count={r.stock_qty}
                      sold_count={r.sold_count}
                    />
                  );
                })}
              </Carousel>
            </>
          ) : null}
        </Container>
      ) : null}
      <Actions>
        <div>
          <Share onClick={() => setShare(true)}>
            <i className="fa fa-share-alt" />
            <span>Bagikan</span>
          </Share>
          <Cart onClick={() => setShowQuantityModal(true)}>
            <i className="fa fa-shopping-cart" />
            <span>Tambakan ke Keranjang</span>
          </Cart>
        </div>
      </Actions>

      {share ? (
        <Modal>
          <ModalContainer onClick={() => setShare(false)}>
            <ModalWrapper>
              <ModalTitle>
                Bagikan
                <button onClick={() => setShare(false)}>
                  <i className="fa fa-close" />
                </button>
              </ModalTitle>
              {data ? (
                <ShareContent>
                  <WhatsappShareButton
                    quote={data.name}
                    url={shareURL(data.slug)}
                  >
                    <WhatsappIcon size={48} round />
                  </WhatsappShareButton>
                  <LineShareButton quote={data.name} url={shareURL(data.slug)}>
                    <LineIcon size={48} round />
                  </LineShareButton>
                  <TelegramShareButton
                    quote={data.name}
                    url={shareURL(data.slug)}
                  >
                    <TelegramIcon size={48} round />
                  </TelegramShareButton>
                </ShareContent>
              ) : null}
            </ModalWrapper>
          </ModalContainer>
        </Modal>
      ) : null}

      {showQuantityModal ? (
        <Modal>
          <ModalContainer>
            <SelectQuantityModalWrapper>
              <ModalTitle>
                Jumlah
                <button onClick={() => setShowQuantityModal(false)}>
                  <i className="fa fa-close" />
                </button>
              </ModalTitle>
              <QuantityContent>
                <select
                  onChange={(event) => {
                    setDozen(Number(event.target.value));
                    setQuantity(Number(event.target.value) * 12 + pcs);
                  }}
                  value={dozen}
                  style={{ marginRight: 8 }}
                >
                  <option key={0} value={0}>
                    0 lusin
                  </option>
                  {[...Array(countDozen(data.stock_qty)).keys()].map((_, i) => {
                    return (
                      <option key={i + 1} value={i + 1}>
                        {i + 1} lusin
                      </option>
                    );
                  })}
                </select>
                <select
                  onChange={(event) => {
                    setPcs(Number(event.target.value));
                    setQuantity(dozen * 12 + Number(event.target.value));
                  }}
                  value={pcs}
                >
                  <option value={0}>0 pcs</option>
                  <option value={4}>4 pcs</option>
                  <option value={8}>8 pcs</option>
                </select>
                {dozen * 12 + pcs > data.stock_qty ? (
                  <p>Pembelian anda melebihi stok!</p>
                ) : null}
              </QuantityContent>
              <ModalFooter>
                <button onClick={() => setShowQuantityModal(false)}>
                  Batal
                </button>
                {dozen * 12 + pcs > data.stock_qty ? (
                  <button
                    onClick={() => {
                      setQuantity(dozen * 12 + pcs);
                      const payload = { product_id: data.id, qty: quantity };
                      if (cookies.access_token) {
                        dispatch(
                          actions.addToCart(payload, { toast: addToast }),
                        );
                        setShowQuantityModal(false);
                      } else {
                        window.localStorage.setItem(
                          'add-to-cart',
                          JSON.stringify(payload),
                        );
                        setTimeout(() => {
                          history.push('/login');
                        }, 300);
                      }
                    }}
                    disabled
                  >
                    Beli
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      const payload = { product_id: data.id, qty: quantity };
                      if (cookies.access_token) {
                        dispatch(
                          actions.addToCart(payload, { toast: addToast }),
                        );
                        setShowQuantityModal(false);
                      } else {
                        window.localStorage.setItem(
                          'add-to-cart',
                          JSON.stringify(payload),
                        );
                        setTimeout(() => {
                          history.push('/login');
                        }, 300);
                      }
                    }}
                  >
                    Beli
                  </button>
                )}
              </ModalFooter>
            </SelectQuantityModalWrapper>
          </ModalContainer>
        </Modal>
      ) : null}
    </div>
  );
}

export default connect(({ explore }) => {
  return {
    loading: explore.loading.product,
    data: explore.detail.product,
    related: explore.data.relatedProducts,
    categoryRelated: explore.data.categoryRelatedProducts,
  };
})(ProductDetail);
