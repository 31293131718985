import * as c from './constants';
import api from './../../../utils/api';

export const fetchAddresses = () => {
  return async (dispatch) => {
    dispatch({
      type: c.ADDRESS_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get('/address');
      if (res.success) {
        dispatch({
          type: c.ADDRESSES_FULFILLED,
          payload: res.data,
        });
      } else {
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};

export const addNewAddress = (data, { toast, history }) => {
  return async (dispatch) => {
    dispatch({ type: c.ADDRESS_LOADING, loading: true });
    try {
      const admRegion = data.administrative.split(', ');
      const address = {
        ...data,
        administrative: undefined,
        province: admRegion[3],
        regency: admRegion[2],
        district: admRegion[1],
        village: admRegion[0],
        longitude: 0.0,
        latitude: 0.0,
      };

      const { data: res } = await api.post('/address', address);
      if (res.success) {
        dispatch(fetchAddresses());
        history.push('/pengaturan/alamat');
        toast('Alamat berhasil ditambah', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast('Terjadi kesalahan', {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};

export const addAddressWithoutDispatch = async (data, cb) => {
  try {
    const { data: res } = await api.post('/address', data);
    if (res.success) {
      cb(null, res.data);
    } else {
      cb('Gagal menyimpan alamat', null);
    }
  } catch (error) {
    cb(error, null);
  }
};

export const fetchAddress = (addressID) => {
  return async (dispatch) => {
    dispatch({
      type: c.ADDRESS_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get(`/address/${addressID}`);
      if (res.success) {
        dispatch({
          type: c.ADDRESS_FULFILLED,
          payload: res.data,
        });
      } else {
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};

export const editAddress = (addressID, data, { toast, history }) => {
  return async (dispatch) => {
    dispatch({ type: c.ADDRESS_LOADING, loading: true });
    try {
      const { data: res } = await api.put(`/address/${addressID}`, data);
      if (res.success) {
        dispatch(fetchAddresses());
        history.push(`/pengaturan/alamat/${addressID}`);
        toast('Alamat berhasil diubah', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast('Terjadi kesalahan', {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};

export const removeAddress = (addressID, { toast }) => {
  return async (dispatch) => {
    dispatch({ type: c.ADDRESS_LOADING, loading: true });
    try {
      const { data: res } = await api.delete(`/address/${addressID}`);
      if (res.success) {
        dispatch(fetchAddresses());
        toast('Alamat berhasil dihapus', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast('Terjadi kesalahan', {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};

export const setDefaultAddress = (addressID, { toast }) => {
  return async (dispatch) => {
    dispatch({ type: c.ADDRESS_LOADING, loading: true });
    try {
      const { data: res } = await api.post(`/address/${addressID}/default`);
      if (res.success) {
        dispatch(fetchAddresses());
        toast('Perubahan berhasil tersimpan', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast('Terjadi kesalahan', {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.ADDRESS_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.ADDRESS_LOADING, loading: false });
    }
  };
};
