export const statusOrderMapper = {
  WAITING_PAYMENT: 'Menunggu Pembayaran',
  PAYMENT_RECEIVED: 'Pembayaran Diterima',
  PREPARING: 'Barang Disiapkan',
  READY: 'Siap Dikirim',
  DELIVERING: 'Sedang Dikirim',
  DELIVERED: 'Diterima',
  DONE: 'Selesai',
  CANCELLED: 'Dibatalkan',
  REJECTED: 'Ditolak',
};
