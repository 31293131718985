import * as c from './constants';
import api from './../../../utils/api';

export const fetchCarts = () => {
  return async (dispatch) => {
    dispatch({
      type: c.CART_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get('/cart');
      if (res.success) {
        dispatch({
          type: c.CARTS_FULFILLED,
          payload: res.data,
        });
      } else {
        dispatch({ type: c.CART_LOADING, loading: false });
      }
    } catch (error) {
      dispatch({ type: c.CART_LOADING, loading: false });
    }
  };
};

export const addToCart = (data, { toast }) => {
  return async (dispatch) => {
    dispatch({ type: c.CART_LOADING, loading: true });
    try {
      const { data: res } = await api.post('/cart', data);
      if (res.success) {
        dispatch(fetchCarts());
        dispatch(fetchCartCount());
        toast('Item berhasil ditambahkan ke keranjang', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.CART_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.CART_LOADING, loading: false });
    }
  };
};

export const buyAgain = (items, { toast, history }) => {
  return async (dispatch) => {
    dispatch({ type: c.CART_LOADING, loading: true });
    try {
      const bulkAddToCart = items.map((data) => {
        return api.post('/cart', data);
      });

      const response = await Promise.all(bulkAddToCart);
      const errors = response
        .map(({ data: res }, idx) =>
          res.success ? null : `${idx + 1}. ${res.message}`,
        )
        .filter((x) => x);

      dispatch(fetchCarts());
      dispatch(fetchCartCount());

      if (errors.length === 0) {
        toast('Item berhasil ditambahkan ke keranjang', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        toast(
          `Berhasil ditambah ke keranjang. Namun ${errors.length} item tidak memiliki stok yang cukup.`,
          {
            appearance: 'warning',
            autoDismiss: true,
          },
        );
        dispatch({ type: c.CART_LOADING, loading: false });
      }
      setTimeout(() => {
        history.push('/keranjang');
      }, 800);
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.CART_LOADING, loading: false });
    }
  };
};

export const updateCart = (cartID, qty, { toast }) => {
  return async (dispatch) => {
    dispatch({ type: c.CART_LOADING, loading: true });
    try {
      const { data: res } = await api.put('/cart/' + cartID, { qty });
      if (res.success) {
        dispatch(fetchCarts());
        dispatch(fetchCartCount());
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({ type: c.CART_LOADING, loading: false });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({ type: c.CART_LOADING, loading: false });
    }
  };
};

export const fetchCartCount = () => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.get('/cart/count');
      if (res.success) {
        dispatch({
          type: c.CARTS_COUNT_FULFILLED,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const resetCart = () => {
  return async (dispatch) => {
    dispatch({
      type: c.CARTS_COUNT_FULFILLED,
      payload: 0,
    });

    dispatch({
      type: c.CARTS_FULFILLED,
      payload: [],
    });
  };
};

export const createOrder = (data, { toast, history }) => {
  return async (dispatch) => {
    dispatch({ type: c.CHECKOUT_LOADING, loading: true });
    try {
      const { data: res } = await api.post('/order', data);
      if (res.success) {
        dispatch(fetchCarts());
        dispatch(fetchCartCount());
        toast('Order berhasil.', {
          appearance: 'success',
          autoDismiss: true,
        });

        history.push('/pengaturan/order/' + res.data.id);
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      toast('Terjadi kesalahan:' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      dispatch({ type: c.CHECKOUT_LOADING, loading: false });
    }
  };
};
