import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import s from 'styled-components';

import config from '../../../config';
import { actions } from '../../../modules/reducers';

const Wrapper = s.div`
  margin: 48px auto 0;
  max-width: 550px;

  h3 {
    fonts-size: 14px;
    font-weight: 400;
    padding: 16px 16px 8px;
    margin: 0;
  }

  p {
    font-size: 13px;
    color: #848484;
    margin: 0;
    padding: 0 16px 16px;
    border-bottom: 8px solid #EFEFEF;
  }
`;

const List = s.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 16px;
    border-bottom: 1px solid #EEE;

    a {
      display: flex;
      text-decoration: none;
      color: #111;
      justify-content: space-between;

      span {
        font-size: 13px;
      }
    }
  }
`;

const Loader = () => (
  <div style={{ paddingTop: 16, borderBottom: '8px solid #EEE' }}>
    <div
      style={{
        background: '#EEE',
        width: 150,
        height: 25,
        margin: '8px 16px',
      }}
    />
    <div
      style={{
        background: '#EEE',
        width: 100,
        height: 18,
        margin: '8px 16px',
      }}
    />
  </div>
);

const Profile = ({ history, dispatch, data, loading }) => {
  const [, , removeCookie] = useCookies([config.COOKIE_NAME]);
  React.useEffect(() => {
    dispatch(actions.fetchProfile());
  }, [dispatch]);

  return (
    <Wrapper>
      {loading ? <Loader /> : null}
      {data ? (
        <>
          <h3>{data.name}</h3>
          <p>{data.phone}</p>
        </>
      ) : null}
      <List>
        <li>
          <Link to="/pengaturan/profil">
            <span>Data Pribadi</span>
            <span>
              <i className="fa fa-chevron-right" />
            </span>
          </Link>
        </li>
        <li>
          <Link to="/pengaturan/alamat">
            <span>Alamat</span>
            <span>
              <i className="fa fa-chevron-right" />
            </span>
          </Link>
        </li>
        <li>
          <Link to="/pengaturan/order">
            <span>Histori Order</span>
            <span>
              <i className="fa fa-chevron-right" />
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/logout"
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault();
              removeCookie('access_token');
              dispatch(actions.resetCart());
              setTimeout(() => {
                history.push('/');
              }, 1000);
            }}
          >
            <span>Logout</span>
          </Link>
        </li>
      </List>
    </Wrapper>
  );
};

export default connect(({ user }) => {
  return {
    data: user.detail,
    loading: user.loading,
  };
})(Profile);
