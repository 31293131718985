import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { InfiniteScroll } from 'react-simple-infinite-scroll';

import { Container, ModelWrapper, Category, Name } from './components/helpers';

import { actions } from '../../../modules/reducers';
import { getImage } from '../../../utils/image';

const ItemLoader = () => (
  <Category>
    <span
      style={{
        display: 'block',
        background: '#DDD',
        borderRadius: '50%',
        width: 70,
        height: 70,
        margin: '0 auto',
      }}
    ></span>
    <span
      style={{
        fontSize: 10,
        display: 'block',
        textAlign: 'center',
        marginTop: 4,
        width: 70,
        height: 10,
        margin: '4px auto 0',
        background: '#DDD',
      }}
    ></span>
  </Category>
);

const CategoryPage = ({ dispatch, loading, data, pagination }) => {
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(actions.fetchCategories({ page, limit: 36 }));
  }, [dispatch, page]);

  const loadMore = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return (
    <Container>
      <InfiniteScroll
        throttle={300}
        threshold={100}
        isLoading={loading}
        hasMore={pagination.has_next}
        onLoadMore={loadMore}
      >
        <ModelWrapper>
          {data.map((category) => {
            return (
              <Category key={category.id}>
                <Link to={`/kategori/${category.slug}`}>
                  <figure>
                    <img
                      src={getImage(category.picture, 50, '1:1')}
                      alt={category.name}
                    />
                  </figure>
                  <Name>
                    <Truncate lines={1} ellipsis="...">
                      {category.name}
                    </Truncate>
                  </Name>
                </Link>
              </Category>
            );
          })}
        </ModelWrapper>
      </InfiniteScroll>
      {loading ? (
        <ModelWrapper>
          {[...Array(36).keys()].map((m) => {
            return <ItemLoader key={m} />;
          })}
        </ModelWrapper>
      ) : null}
    </Container>
  );
};

export default connect(({ explore }) => {
  return {
    loading: explore.loading.category,
    data: explore.data.category,
    pagination: explore.pagination.category,
  };
})(CategoryPage);
