import * as c from './constants';
import api from '../../../utils/api';
import { objectToQueryString } from '../../../utils/url';

const loading = (section) => {
  return {
    type: c.EXPLORE_LOADING,
    section,
  };
};

export const fetchExplore = (
  section,
  params = { page: 1, limit: 10, name: '' }
) => {
  return async (dispatch) => {
    dispatch(loading(section));
    try {
      const qs = objectToQueryString(params);
      const { data: res } = await api.get(`/product?${qs}`);
      dispatch({
        type: c.EXPLORE_FULFILLED,
        section,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      // Handle error later
      // Show global popup
    }
  };
};

export const resetData = (section) => {
  return async (dispatch) => {
    dispatch({
      type: c.EXPLORE_EMPTY_DATA,
      section,
    });
  };
};

export const exploreReset = (emptyAll = false) => {
  return async (dispatch) => {
    dispatch({
      type: c.EXPLORE_RESET_SEARCH,
    });

    if (emptyAll) {
      dispatch(resetData('explore'));
    }
  };
};

export const exploreSearch = ({ query, page } = { query: '', page: 1 }) => {
  return async (dispatch) => {
    dispatch({
      type: c.EXPLORE_SEARCH,
      query: query,
      page: page,
    });
  };
};

export const fetchCategories = (params = { page: 1, limit: 12 }) => {
  return async (dispatch) => {
    const section = 'category';
    dispatch(loading(section));
    try {
      const qs = objectToQueryString(params);
      const { data: res } = await api.get(`/category?${qs}&homepage=1`);
      dispatch({
        type: c.EXPLORE_FULFILLED,
        section,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
      // Handle error later
      // Show global popup
    }
  };
};

export const fetchCategory = (slug) => {
  return async (dispatch) => {
    const section = 'category';
    dispatch(loading(section));
    try {
      const { data: res } = await api.get(`/category/${slug}/detail`);
      dispatch({
        type: c.EXPLORE_DETAIL_FULFILLED,
        section,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchProductInCategory = (
  slug,
  page = 1,
  reset = false,
  isOnSale = false
) => {
  return async (dispatch) => {
    const section = 'productCategory';
    if (reset) {
      dispatch(resetData(section));
    }
    dispatch(loading(section));
    try {
      const { data: res } = await api.get(
        `/category/${slug}/products?page=${page}&on_sale=${isOnSale ? 1 : 0}`
      );
      dispatch({
        type: c.EXPLORE_FULFILLED,
        section,
        payload: res.data,
        pagination: res.pagination,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchProduct = (slug) => {
  return async (dispatch) => {
    const section = 'product';
    dispatch(loading(section));
    try {
      const { data: res } = await api.get(`/product/${slug}/detail`);
      dispatch({
        type: c.EXPLORE_DETAIL_FULFILLED,
        section,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchRelatedProduct = (slug) => {
  return async (dispatch) => {
    const section = 'product';
    dispatch(loading(section));
    try {
      const { data: res } = await api.get(`/product/${slug}/related`);
      if (res.success) {
        dispatch({
          type: c.EXPLORE_RELATED_FULFILLED,
          section,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchCategoryRelatedProduct = (slug) => {
  return async (dispatch) => {
    const section = 'product';
    dispatch(loading(section));
    try {
      const { data: res } = await api.get(`/product/${slug}/in-category`);
      if (res.success) {
        dispatch({
          type: c.EXPLORE_CATEGORY_RELATED_FULFILLED,
          section,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};
