import s from 'styled-components';

export const Wrapper = s.div`
    width: 100%;
    max-height: 290px;
    overflow: hidden;
`;

export const Slider = s.div`
    overflow-x: auto;
`;

export const Items = s.div`
    white-space: nowrap;
    height: 270px;
    padding-bottom: 20px;

    > div {
        display: inline-block;
        margin-right: 16px;
    }
`;
