import s from 'styled-components';

export const ModalContainer = s.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = s.div`
  background: #FFF;
  border-radius: 4px;
  max-width: 550px;
  width: 96%;
  margin: 0 auto;
`;

export const ModalTitle = s.div`
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 16px 16px 8px;
  border-bottom: 1px solid #EEE;

  button {
    border: none;
    background: none;
    position: absolute;
    top: 12px;
    right: 4px;
    font-size: 16px;
  }
`;

export const AddressCard = s.div`
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border: 1px solid #EEE;
  margin-bottom: 16px;
`;

export const AddressHeader = s.div`
  padding: 8px 16px 0;
  position: relative;
  
  span {
    font-size: 12px;
    margin-right: 4px;
    display: inline-block;
    color: #888;
  }

  small {
    position: absolute;
    top: 10px;
    right: 16px;
    background: darkred;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    margin-right: 4px;
  }
`;

export const AddressContent = s.div`
  padding: 0 16px 8px;
  
  span {
    display: block;
    font-size: 13px;
    color: #666;
    margin-bottom: 4px;

    &:first-child {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

export const AddressFooter = s.div`
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #EEE;

  button {
    text-decoration: none;
    display: block;
    padding: 8px;
    width: 100%;
    text-align: center;
    font-size: 10px;
    border: 1px solid darkred;
    font-weight: 600;
    border-radius: 4px;
    background: darkred;
    color: #FFF;
  }
`;
