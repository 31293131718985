import React from 'react';
import { connect } from 'react-redux';
import { InfiniteScroll } from 'react-simple-infinite-scroll';

import Header from './components/Header';
import { Container, ProductWrapper } from './components/helpers';
import { ProductCard } from './../../../components';

import { actions } from '../../../modules/reducers';

const CategoryDetailPage = ({
  match,
  dispatch,
  isOnSale,
  loading,
  data,
  productLoading,
  products,
  pagination,
}) => {
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(actions.fetchCategory(match.params.slug));
  }, [dispatch, match.params.slug]);

  React.useEffect(() => {
    dispatch(
      // reset if page 1, otherwise concat data
      actions.fetchProductInCategory(
        match.params.slug,
        page,
        page === 1,
        isOnSale,
      ),
    );
  }, [dispatch, match.params.slug, page, isOnSale]);

  const loadMore = React.useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return (
    <Container>
      <Header
        {...data}
        count={pagination.rowCount}
        loading={loading || productLoading}
      />

      <InfiniteScroll
        throttle={300}
        threshold={100}
        isLoading={productLoading}
        hasMore={pagination.has_next}
        onLoadMore={loadMore}
      >
        <ProductWrapper>
          {products.map((product, idx) => {
            let pictures = [];
            try {
              pictures = JSON.parse(product.pictures);
            } catch (err) {}

            return (
              <div
                key={idx}
                style={{
                  width: '48%',
                  marginBottom: 16,
                  marginLeft: idx % 2 === 0 ? 0 : '1%',
                }}
              >
                <ProductCard
                  key={product.id}
                  name={product.name}
                  price={product.price}
                  picture={pictures[0]}
                  slug={product.slug}
                  on_sale={product.on_sale}
                  sale_price={product.sale_price}
                  count={product.stock_qty}
                  sold_count={product.sold_count}
                />
              </div>
            );
          })}
          {productLoading
            ? [...Array(6).keys()].map((i, idx) => (
                <div
                  key={i}
                  style={{
                    width: '48%',
                    marginBottom: 16,
                    marginLeft: idx % 2 === 0 ? 0 : '1%',
                  }}
                >
                  <ProductCard isLoading />
                </div>
              ))
            : null}
        </ProductWrapper>
      </InfiniteScroll>
    </Container>
  );
};

export default connect(({ explore }, { location }) => {
  const query = new URLSearchParams(location.search);
  return {
    isOnSale: query.get('on_sale') === '1',
    loading: explore.loading.category,
    data: explore.detail.category,
    products: explore.data.productCategory,
    productLoading: explore.loading.productCategory,
    pagination: explore.pagination.productCategory,
  };
})(CategoryDetailPage);
