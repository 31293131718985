import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { actions } from '../../../modules/reducers';
import { Form } from '../../../components';

const AddAddress = ({ dispatch, history }) => {
  const { register, errors, handleSubmit } = useForm();
  const { addToast } = useToasts();

  React.useEffect(() => {
    register('address', { required: true });
  }, [register]);

  const onSubmit = (data) => {
    dispatch(actions.addNewAddress(data, { toast: addToast, history }));
  };

  return (
    <Form.Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.FormGroup>
          <Form.Label>Nama</Form.Label>
          <Form.Input
            type="text"
            name="name"
            placeholder="Contoh: Toko Cabang Semarang"
            ref={register({ required: true })}
          />
          {errors.name ? (
            <Form.HelperText error>
              Nama alamat tidak boleh kosong
            </Form.HelperText>
          ) : null}
        </Form.FormGroup>
        <Form.FormGroup>
          <Form.Label>Nama Penerima</Form.Label>
          <Form.Input
            type="text"
            name="receiver"
            ref={register({ required: true })}
          />
          {errors.receiver ? (
            <Form.HelperText error>
              Nama penerima tidak boleh kosong
            </Form.HelperText>
          ) : null}
        </Form.FormGroup>
        <Form.FormGroup>
          <Form.Label>No Hp Penerima</Form.Label>
          <Form.Input
            type="text"
            name="phone"
            ref={register({ required: true })}
          />
          {errors.phone ? (
            <Form.HelperText error>
              No handphone tidak boleh kosong
            </Form.HelperText>
          ) : null}
        </Form.FormGroup>
        <Form.FormGroup>
          <Form.Label>Alamat</Form.Label>
          <Form.TextArea
            ref={register({ required: true })}
            name="address"
            placeholder="Contoh: Jl. Raya Pasar Minggu No 1"
          />
          {errors.address ? (
            <Form.HelperText error>Alamat tidak boleh kosong</Form.HelperText>
          ) : null}
        </Form.FormGroup>

        <Form.PrimaryButton type="submit">Simpan Alamat</Form.PrimaryButton>
      </form>
    </Form.Wrapper>
  );
};

export default connect()(AddAddress);
