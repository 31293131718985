import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import s from 'styled-components';

const TabBottom = s.div`
    position: fixed;
    background: #FFF;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #EEE;
`;

const TabMenuWrapper = s.div`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;

const TabMenu = s.div`
    padding: 8px 0 4px;
    width: 25%;
    text-align: center;

    a {
        display: block;
        text-decoration: none;
        color: #444;
        ${(props) => (props.isActive ? 'color: darkred;' : '')}
        position: relative;

        .cart-count {
            position: absolute;
            text-align: center;
            background: red;
            display: inline-block;
            font-size: 10px;
            color: white;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            margin-top: -5px;
        }

        > span {
            font-size: 10px;
            display: block;
            margin-bottom: 2px;

            > i {
              font-size: 20px;
            }
        }
    }
`;

const tabMenu = [
  { name: 'Beranda', to: '/', icon: 'home' },
  { name: 'Terbaru', to: '/eksplor', icon: 'star' },
  { name: 'Diskon', to: '/diskon', icon: 'tags' },
  { name: 'Keranjang', to: '/keranjang', icon: 'shopping-cart' },
  { name: 'Pengaturan', to: '/pengaturan', icon: 'cog' },
];

const TabBar = (props) => {
  const location = useLocation();
  const hideTabBar = !tabMenu.map((t) => t.to).includes(location.pathname);

  if (hideTabBar) {
    return null;
  }

  return (
    <TabBottom>
      <TabMenuWrapper>
        {tabMenu.map((menu) => {
          const iconStyle = `fa fa-${menu.icon}`;
          return (
            <TabMenu key={menu.name} isActive={location.pathname === menu.to}>
              <Link to={menu.to}>
                {menu.to === '/keranjang' && props.cartCount ? (
                  <span className="cart-count">{props.cartCount}</span>
                ) : null}
                <span>
                  <i className={iconStyle} />
                </span>
                <span>{menu.name}</span>
              </Link>
            </TabMenu>
          );
        })}
      </TabMenuWrapper>
    </TabBottom>
  );
};

export default TabBar;
