import { Cookies } from 'react-cookie';
import * as c from './constants';
import api from './../../../utils/api';
import cart from '../cart';

const cookies = new Cookies();

export const fetchProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: c.USER_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get('/profile');
      if (res.success) {
        dispatch({
          type: c.USER_FULFILLED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: c.USER_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      cookies.remove('access_token');
      dispatch({
        type: c.USER_LOADING,
        loading: false,
      });
    }
  };
};

export const register = (data, { history, toast }) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.post('/register', data);

      if (res.token) {
        cookies.set('access_token', res.token);

        const cartPayload = window.localStorage.getItem('add-to-cart');

        setTimeout(() => {
          if (cartPayload) {
            const cartData = JSON.parse(cartPayload);
            dispatch(cart.actions.addToCart(cartData, { toast }));

            window.localStorage.removeItem('add-to-cart');
          }

          dispatch(cart.actions.fetchCartCount());
          dispatch(cart.actions.fetchCarts());
          history.push('/');
        }, 500);
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({
          type: c.USER_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      toast('Terjadi kesalahan: ' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({
        type: c.USER_LOADING,
        loading: false,
      });
    }
  };
};

export const login = (data, { history, toast }) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.post('/login', data);

      if (res.token) {
        cookies.set('access_token', res.token);

        const cartPayload = window.localStorage.getItem('add-to-cart');

        setTimeout(() => {
          if (cartPayload) {
            const cartData = JSON.parse(cartPayload);
            dispatch(cart.actions.addToCart(cartData, { toast }));

            window.localStorage.removeItem('add-to-cart');
          }

          dispatch(cart.actions.fetchCartCount());
          dispatch(cart.actions.fetchCarts());
          history.push('/');
        }, 500);
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({
          type: c.USER_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      toast('Terjadi kesalahan: ' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({
        type: c.USER_LOADING,
        loading: false,
      });
    }
  };
};

export const updateProfile = (data, { toast }) => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.put('/profile', data);

      if (res.success) {
        dispatch({
          type: c.USER_FULFILLED,
          payload: res.data,
        });
      } else {
        toast('Terjadi kesalahan: ' + res.message, {
          appearance: 'error',
          autoDismiss: true,
        });
        dispatch({
          type: c.USER_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      toast('Terjadi kesalahan: ' + error, {
        appearance: 'error',
        autoDismiss: true,
      });
      dispatch({
        type: c.USER_LOADING,
        loading: false,
      });
    }
  };
};

export const fetchBanks = () => {
  return async (dispatch) => {
    try {
      const { data: res } = await api.get('/bank');

      if (res.success) {
        dispatch({
          type: c.BANKS,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchOption = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchBanks());

      const { data: res } = await api.get('/option');

      if (res.success) {
        dispatch({
          type: c.APP_OPTIONS,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};
