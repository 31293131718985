import s from 'styled-components';

export const Title = s.h2`
  font-size: 16px;
  text-transform: uppercase;
  margin: 0;
`;

export const Loader = s.div`
    height: 18px;
    background: #EEE;
    width: 100px;
    margin-bottom: 8px;
`;

export const Description = s.span`
    color: #666;
    padding: 5px 0;
    font-size: 12px;
`;

export const ImageWrapper = s.div`
    background: #ececec;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
    height: 100px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #DDD;

    img {
        width: 100%;
        height: auto;
    }
`;

export const HeaderWrapper = s.div`
    display: flex;
    flex-direction: row;
    padding: 0 16px 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #EEE;
`;

export const ContentWrapper = s.div`
    display: flex;
    padding: 0 16px;
    flex-direction: column;
`;

export const ProductCount = s.span`
    color: #bdbdbd;
    font-size: 12px;
`;

export const Container = s.div`
    margin: 60px auto 80px;
    max-width: 550px;
    min-height: 100vh;
`;

export const ProductWrapper = s.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 16px;
`;
