import s from 'styled-components';

export const Table = s.table`
  width: 100%;

  thead tr th {
    font-size: 14px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DDD;
  }

  tbody tr td {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EEE;
  }
`;

export const Item = s.div`
  display: flex;
  flex-flow: row wrap;
`;

export const Figure = s.div`
  width: 60px;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Meta = s.div`
  flex: 1;
  margin-left: 8px;
`;

export const Name = s.p`
  font-size: 14px;
  color: #666;
  margin: 0 0 8px;
`;

export const Price = s.p`
  font-size: 12px;
  color: #000;
  margin: 0 0 16px;
  font-weight: 700;
`;

export const Quantity = s.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  button {
    width: 28px;
    height: 28px;
    border: none;
    color: #FFF;
    padding: 0;
    background: darkred;
    border-radius: 50%;
    text-align: center;

    &:disabled {
      background: #CCC;
    }
  }

  input {
    width: 100px;
    margin: 0 8px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DDD;
  }
`;
