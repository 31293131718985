import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { actions } from '../../../modules/reducers';
import {
  Wrapper,
  Loader,
  Detail,
  MetaCard,
  Items,
  Product,
  Address,
  CancelOrder,
  BuyAgain,
} from './helpers';
import { statusOrderMapper } from './utils';
import { countQuantity } from '../../../utils/number';
import { getImage } from '../../../utils/image';

const DetailOrder = ({
  dispatch,
  match,
  history,
  loading,
  data,
  option,
  banks,
}) => {
  const { addToast } = useToasts();
  React.useEffect(() => {
    dispatch(actions.fetchOrder(match.params.id));
  }, [dispatch, match.params.id]);

  const handleBuyAgain = (data) => {
    dispatch(actions.buyAgain(data, { toast: addToast, history }));
  };

  const handleCancel = () => {
    dispatch(actions.cancelOrder(match.params.id, { toast: addToast }));
  };

  return (
    <Wrapper>
      {loading ? (
        <div style={{ padding: 16 }}>
          <Loader />
          <Loader />
        </div>
      ) : (
        ''
      )}
      {data ? (
        <>
          <Detail style={{ paddingTop: 16 }}>
            <span style={{ color: 'darkred' }}>{data.invoice_no}</span>
          </Detail>
          <Detail>
            <span>Status</span>
            <span
              style={{
                background: '#EEE',
                border: '1px solid #DDD',
                borderRadius: 4,
                padding: 4,
                fontSize: 11,
              }}
            >
              {statusOrderMapper[data.status]}
            </span>
          </Detail>
          <Detail>
            <span>Tanggal Transaksi</span>
            <span>{new Date(data.created_at).toLocaleString()}</span>
          </Detail>
          <MetaCard>
            <h3>Daftar Item Pembelian</h3>
            <Items>
              {data.items.map((item) => {
                let pictures = [];
                try {
                  pictures = JSON.parse(item.picture);
                } catch (err) {}

                return (
                  <Product key={item.id}>
                    <div>
                      <img
                        src={getImage(pictures[0], 100, '1:1')}
                        alt={item.name}
                      />
                    </div>
                    <div>
                      <p>{item.name}</p>
                      <p>{countQuantity(item.quantity)}</p>
                      <p>Rp. {item.price.toLocaleString('id')}</p>
                    </div>
                  </Product>
                );
              })}
            </Items>
            <Detail noPadding>
              <span>Total Belanja</span>
              <span style={{ color: 'darkred', fontWeight: 700 }}>
                Rp. {data.total.toLocaleString('id')}
              </span>
            </Detail>
          </MetaCard>

          <MetaCard>
            <h3>Detail Pengiriman</h3>
            {data.delivery_type === 'PICKUP' ? (
              <Detail noPadding>
                <span>Ambil di Toko</span>
              </Detail>
            ) : (
              <>
                <Detail noPadding>
                  <span>Penerima</span>
                  <span>{data.customer_receiver_name}</span>
                </Detail>
                <Detail noPadding>
                  <span>No Handphone Penerima</span>
                  <span>{data.customer_receiver_phone}</span>
                </Detail>
                <Address>
                  <span>Alamat Pengiriman</span>
                  <span>{data.customer_address}</span>
                </Address>
              </>
            )}
          </MetaCard>

          <MetaCard>
            <h3>Informasi Pembayaran</h3>
            <p>
              Untuk proses pembayaran harap transfer ke no rekening dibawah ini.
            </p>
            <p>
              Jika anda sudah melakukan transfer, harap konfirmasi melalui nomor
              WA{' '}
              <Link to={`https://wa.me/${option.whatsapp_number}`}>
                {option.whatsapp_number}
              </Link>{' '}
              dengan menyertakan bukti transfer.
            </p>
            {banks.map((bank) => (
              <React.Fragment key={bank.id}>
                <h3 style={{ color: 'darkred' }}>{bank.bank_name}</h3>
                <Detail noPadding>
                  <span>No. Rekening</span>
                  <span>
                    <input
                      type="text"
                      value={bank.value}
                      id={`clipboard-${bank.id}`}
                      style={{ opacity: 0 }}
                    />
                    <button
                      onClick={() => {
                        const copyText = document.getElementById(
                          `clipboard-${bank.id}`,
                        );
                        copyText.select();
                        copyText.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        addToast('Nomor rekening berhasil disalin.', {
                          appearance: 'success',
                          autoDismiss: true,
                        });
                      }}
                    >
                      {bank.value}
                      <i className="fa fa-copy" />
                    </button>
                  </span>
                </Detail>
                <Detail noPadding>
                  <span>Atas Nama</span>
                  <span>{bank.account_name}</span>
                </Detail>
              </React.Fragment>
            ))}
          </MetaCard>

          <MetaCard>
            {data.status === 'WAITING_PAYMENT' ? (
              <CancelOrder onClick={handleCancel}>Batalkan Pesanan</CancelOrder>
            ) : null}
            <BuyAgain
              onClick={() => {
                const items = data.items.map((item) => ({
                  product_id: item.product_id,
                  qty: item.quantity,
                }));

                handleBuyAgain(items);
              }}
            >
              Beli Lagi
            </BuyAgain>
          </MetaCard>
        </>
      ) : null}
    </Wrapper>
  );
};

export default connect(({ order, user }) => {
  return {
    data: order.detail,
    loading: order.loading,
    option: user.options.reduce((obj, data) => {
      obj[data.key] = data.value;
      return obj;
    }, {}),
    banks: user.banks,
  };
})(DetailOrder);
