import React from 'react';
import s from 'styled-components';

const TotalWrapper = s.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 16px 0;
`;

const TotalText = s.p`
  color: #666;
  margin: 0 0 4px;
  font-size: 12px;
`;

const TotalPrice = s.p`
  font-weight: 700;
  font-size: 18px;
  margin: 0;
`;

const BuyButton = s.button`
  background: darkred;
  color: #EEE;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  min-width: 120px;
  text-align: center;
  display: block;
  margin-top: 10px;

  :disabled {
    background: #666;
  }
`;

function Total({ total, history, buttonTitle, onCheckout, checkoutDisabled }) {
  return (
    <TotalWrapper>
      <div>
        <TotalText>Total harga:</TotalText>
        <TotalPrice>Rp. {total.toLocaleString('id')}</TotalPrice>
      </div>
      <div>
        {!onCheckout && history ? (
          <BuyButton onClick={() => history.push('/checkout')}>
            {buttonTitle || 'Beli'}
          </BuyButton>
        ) : (
          <BuyButton disabled={checkoutDisabled} onClick={onCheckout}>
            {buttonTitle || 'Beli'}
          </BuyButton>
        )}
      </div>
    </TotalWrapper>
  );
}

export default Total;
