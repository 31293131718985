import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import {
  Wrapper,
  FormGroup,
  Input,
  Button,
  Label,
  HelperText,
} from './components/helpers';
import { actions } from '../../modules/reducers';

const Register = ({ dispatch, history }) => {
  const { addToast } = useToasts();
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(actions.register(data, { history, toast: addToast }));
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="name" style={{ display: 'inline-block' }}>
            Nama
          </Label>
          <Input
            type="text"
            name="name"
            placeholder="Nama lengkap"
            ref={register({ required: true })}
          />
          {errors.name ? (
            <HelperText error>Nama tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone" style={{ display: 'inline-block' }}>
            No Handphone
          </Label>
          <Input
            type="number"
            name="phone"
            placeholder="08123456789"
            ref={register({ required: true })}
          />
          {errors.phone ? (
            <HelperText error>No handphone tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password" style={{ marginRight: '10px' }}>
            Password
          </Label>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            ref={register({ required: true })}
          />
          {errors.password ? (
            <HelperText error>Password tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Button type="submit">Register</Button>
        </FormGroup>
      </form>
    </Wrapper>
  );
};

export default connect()(Register);
