import React from 'react';
import { useLocation } from 'react-router-dom';
import s from 'styled-components';

const FloatingButton = s.div`
  position: fixed;
  bottom: 65px;
  right: 10px;

  a {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #23D366;
    color: white;
    z-index: 999;
    text-align: center;
    
    i {
      font-size: 40px;
      margin-top: 10px;
    }
  }
`;

const FloatingWA = ({ waNumber }) => {
  const location = useLocation();
  const hideOn = /pengaturan|produk|keranjang|kategori|login|register|checkout/;
  if (!waNumber || hideOn.test(location.pathname)) {
    return null;
  }

  return (
    <FloatingButton>
      <a
        href={`https://wa.me/${waNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp" />
      </a>
    </FloatingButton>
  );
};

export default FloatingWA;
