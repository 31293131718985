import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import {
  Header,
  TabBar,
  ScrollToTop,
  PrivateRoute,
  FloatingWA,
} from './components';
import Home from './pages/Home';
import Explore from './pages/Explore';
import Discount from './pages/Discount';
import { Cart, Checkout } from './pages/Cart';
import {
  Setting,
  Profile,
  Address,
  CreateAddress,
  EditAddress,
  Order,
  OrderDetail,
} from './pages/Setting';
import { CategoryList, CategoryDetail } from './pages/Category';
import { TagList, TagDetail } from './pages/Tag';
import ProductDetail from './pages/Product';
import Login from './pages/Login';
import Register from './pages/Register';

import config from './config';
import { actions } from './modules/reducers';

function App({ dispatch, cartCount, option }) {
  const [cookies] = useCookies([config.COOKIE_NAME]);
  React.useEffect(() => {
    if (cookies.access_token) {
      dispatch(actions.fetchCartCount());
      dispatch(actions.fetchProfile());
    }
    dispatch(actions.fetchOption());
  }, [cookies.access_token, dispatch]);

  return (
    <Router>
      <Header cartCount={cartCount} />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/eksplor" component={Explore} />
        <Route exact path="/diskon" component={Discount} />
        <PrivateRoute exact path="/keranjang" component={Cart} />
        <PrivateRoute exact path="/checkout" component={Checkout} />
        <PrivateRoute exact path="/pengaturan" component={Setting} />
        <PrivateRoute exact path="/pengaturan/profil" component={Profile} />
        <PrivateRoute exact path="/pengaturan/alamat" component={Address} />
        <PrivateRoute exact path="/pengaturan/order" component={Order} />
        <PrivateRoute
          exact
          path="/pengaturan/order/:id"
          component={OrderDetail}
        />
        <PrivateRoute
          exact
          path="/pengaturan/alamat/baru"
          component={CreateAddress}
        />
        <PrivateRoute
          exact
          path="/pengaturan/alamat/:id"
          component={EditAddress}
        />
        <Route exact path="/kategori" component={CategoryList} />
        <Route exact path="/kategori/:slug" component={CategoryDetail} />
        <Route exact path="/produk/:slug" component={ProductDetail} />
        <Route exact path="/tag" component={TagList} />
        <Route exact path="/tag/:slug" component={TagDetail} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
      </Switch>
      <TabBar cartCount={cartCount} />
      <FloatingWA waNumber={option.whatsapp_number} />
    </Router>
  );
}

export default connect(({ cart, user }) => {
  return {
    cartCount: cart.count,
    option: user.options.reduce((obj, data) => {
      obj[data.key] = data.value;
      return obj;
    }, {}),
  };
})(App);
