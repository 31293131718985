import s from 'styled-components';

export const Wrapper = s.div`
    margin: 48px auto 0;
    max-width: 550px;
`;

export const Loader = s.div`
    background: #EEE;
    height: ${(props) => props.height || 100}px;
    width: 100%;
    border-bottom: 16px solid #FFF;
`;

export const Detail = s.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    ${(props) =>
      props.noPadding ? 'padding: 0 0 16px;' : 'padding: 0 16px 16px;'}

    span {
        font-size: 13px;

        &:first-child {
            font-weight: 700;
        }
    }

    button {
        padding: 3px 4px;
        border: 1px solid #CCC;
        background: #EEE;

        i {
            margin-left: 8px;
        }
    }
`;

export const MetaCard = s.div`
    border-top: 8px solid #EEE;
    border-bottom: 8px solid #EEE;
    padding: 16px;

    h3 {
        margin: 0 0 16px;
        font-size: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid #EEE;
    }

    > p {
        font-size: 12px;

        a {
            color: darkred;
            font-weight: 700;
        }
    }
`;

export const Items = s.div``;

export const Product = s.div`
    display: flex;
    margin-bottom: 16px;
    font-size: 14px;

    div {
        &:first-child {
            width: 100px;
            border-radius: 4px;
            overflow: hidden;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &:last-child {
            padding: 0 16px;

            p {
                margin: 0 0 8px;

                &:first-child {
                    font-weight: 700;
                }

                &:nth-child(2) {
                    color: #666;
                    font-size: 12px;
                }

                &:last-child {
                    color: darkred;
                    font-size: 12px;
                }
            }
        }
    }
`;

export const Address = s.div`
    font-size: 13px;

    span {
        display: block;

        &:first-child {
            font-weight: 700;
        }
    }
`;

export const CancelOrder = s.div`
    margin: 0 0 8px;
    border: none;
    border-radius: 4px;
    width: 100%;
    display: block;
    padding: 12px;
    border: 1px solid darkred;
    color: darkred;
    background: #FFF;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
`;

export const BuyAgain = s.button`
    display: block;
    width: 100%;
    background: darkred;
    color: #FFF;
    border: none;
    border-radius: 4px;
    padding: 12px;
    text-align: center;
    font-weight: 700;
`;
