import React from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';

import { AddressCard } from '../../../components';
import { Wrapper, CreateAddressButton } from './components/helpers';

import { actions } from '../../../modules/reducers';

const AddressPage = ({ dispatch, loading, data }) => {
  const [alert, showAlert] = React.useState(null);
  const { addToast } = useToasts();
  React.useEffect(() => {
    dispatch(actions.fetchAddresses());
  }, [dispatch]);

  return (
    <Wrapper>
      <CreateAddressButton to="/pengaturan/alamat/baru">
        <i className="fa fa-plus" />
        Tambah Alamat
      </CreateAddressButton>
      {loading ? (
        <p style={{ textAlign: 'center', fontSize: 12, color: '#666' }}>
          Loading...
        </p>
      ) : null}
      {data.length === 0 && !loading ? (
        <p style={{ textAlign: 'center', fontSize: 12, color: '#666' }}>
          Belum ada alamat
        </p>
      ) : null}
      {data.map((addr, idx) => {
        return (
          <AddressCard
            key={idx}
            {...addr}
            deleteConfirmation={showAlert}
            setAsDefault={(id) =>
              dispatch(actions.setDefaultAddress(id, { toast: addToast }))
            }
          />
        );
      })}
      <SweetAlert
        show={!!alert}
        title="Hapus"
        icon="error"
        showCancel
        confirmBtnText="Hapus"
        cancelBtnText="Batal"
        cancelBtnStyle={{
          border: '1px solid #666',
          padding: '6px 12px',
          textDecoration: 'none',
          borderRadius: 4,
          color: '#111',
        }}
        confirmBtnStyle={{
          border: '1px solid darkred',
          background: 'darkred',
          padding: '6px 12px',
          textDecoration: 'none',
          borderRadius: 4,
          color: '#FFF',
        }}
        onCancel={() => {
          showAlert(null);
        }}
        onConfirm={() => {
          dispatch(actions.removeAddress(alert, { toast: addToast }));
          showAlert(null);
        }}
      >
        Anda yakin ingin menghapus alamat ini?
      </SweetAlert>
    </Wrapper>
  );
};

export default connect(({ address }) => {
  return {
    loading: address.loading,
    data: address.data,
  };
})(AddressPage);
