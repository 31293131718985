import s from 'styled-components';
export const Container = s.div`
  max-width: 550px;
  margin-bottom: 70px;
`;

export const Price = s.div`
  font-size: 22px;
  font-weight: 700;
`;

export const Name = s.div`
  font-size: 14px;
  color: #666;
`;

export const Description = s.div`
  font-size: 13px;
  color: #666;
`;

export const Card = s.div`
  padding: 16px;
  border-bottom: 10px solid #EFEFEF;
`;

export const Subtitle = s.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const Info = s.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > div {
    width: 50%;
    font-size: 13px;
    color: #666;
    margin-top: 10px;

    a {
      text-decoration: none;
      font-size: 13px;
      color: darkred;
    }
  }

  > div:nth-child(even) {
    text-align: right;
  }
`;

export const Actions = s.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #EFEFEF;
  background: #FFF;
  
  > div {
    display: flex;
    padding: 10px 16px;
    max-width: 550px;
    margin: 0 auto;
  }
`;

export const Share = s.button`
  display: inline-block;
  padding: 12px 16px;
  background: #FFF;
  border: 1px solid #000;
  border-radius: 4px;
  font-weight: 700;

  span {
    display: inline-block;
    margin-left: 8px;
  }
`;

export const Cart = s.button`
  display: inline-block;
  background: darkred;
  color: #FFF;
  padding: 12px 16px;
  border: 1px solid darkred;
  border-radius: 4px;
  margin-left: 10px;
  flex: 1;
  font-weight: 700;

  span {
    display: inline-block;
    margin-left: 8px;
  }
`;

export const ModalContainer = s.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = s.div`
  background: #FFF;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px 4px 0 0;
  max-width: 550px;
  margin: 0 auto;
`;

export const ModalTitle = s.div`
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 16px 16px 8px;
  border-bottom: 1px solid #EEE;

  button {
    border: none;
    background: none;
    position: absolute;
    top: 12px;
    right: 4px;
    font-size: 16px;
  }
`;

export const ShareContent = s.div`
  padding: 16px;

  button {
    margin-left: 8px;
  }
`;

export const QuantityContent = s.div`
  padding: 16px;
`;

export const SelectQuantityModalWrapper = s.div`
  background: #FFF;
  border-radius: 4px;
  max-width: 550px;
  min-width: 250px;
`;

export const ModalFooter = s.div`
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  border-top: 1px solid #EEE;
  text-align: right;

  button {
    font-size: 16px;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    min-width: 90px;
    
    &:first-child {
      color: #000;
      border: 1px solid #000;
      background: #FFF;
      margin-right: 8px;
    }
    
    &:last-child {
      background-color: darkred;
      color: #FFF;
      border: 1px solid darkred;  
    }
  }
`;