import React from 'react';
import Slider from 'react-slick';
import s from 'styled-components';

import {getImage} from '../../../utils/image'

const Slide = s.div`
  &:nth-child(odd) {
    margin-left: 4px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const SlideLoader = s.div`
  width: 100%;
  max-height: 70vh;
  background: #DDD;
`;

function SlideShow({ loading, data }) {
  const settings = {
    dots: true,
    swipeToSlide: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div style={{ marginBottom: 32 }}>
      {loading ? (
        <div>
          <SlideLoader />
        </div>
      ) : null}
      <Slider {...settings}>
        {data.map((slide) => {
          return (
            <div key={slide.id}>
              <Slide>
                <img src={getImage(slide.src, 550, '3:4')} alt={slide.id} />
              </Slide>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SlideShow;
