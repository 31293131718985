import s from 'styled-components';

export const Wrapper = s.div`
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    ${(props) => (props.carousel ? 'width: 140px;' : '')}
    ${(props) => (props.firstItem ? 'margin-left: 16px;' : '')}
    ${(props) => (props.isLoading ? 'background: #EEE; height: 236px;' : '')}
`;

export const Figure = s.figure`
    margin: 0;
    padding: 0;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    max-height: 150px;
    position: relative;

    img {
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
        min-height: 140px;
    }
`;

export const Meta = s.div`
    padding: 4px 8px 8px;
    background: #FFF;
`;

export const Name = s.div`
    font-size: 14px;

    a {
        color: #000;
        display: block;
        text-decoration: none;
    }
`;

export const Price = s.div`
    font-size: 14px;
    font-weight: 700;
    ${(props) =>
      props.isOnSale
        ? 'text-decoration: line-through; font-size: 10px; color: #666; line-height: 14px; margin-right: 4px;'
        : ''}
    ${(props) => (props.salePrice ? 'color: red;' : '')}
`;

export const Category = s.div`
    font-size: 10px;
    margin-top: 4px;

    a {
        text-decoration: none;
        background: darkred;
        color: #fff;
        border-radius: 4px;
        display: inline-block;
        padding: 2px 6px;
        font-size: 10px;
    }
`;

export const Discount = s.div`
    position: absolute;
    background: red;
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    top: -2px;
    right: -44px;
    font-size: 12px;
    width: 110px;
    left: auto;
    transform: rotate(45deg);
    padding: 10px 0 5px;
`;
