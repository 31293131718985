import * as c from './constants';
const defaultPaging = {
  page: 1,
  pageSize: 10,
  rowCount: 1,
  pageCount: 1,
  has_next: true,
};

export const initialState = {
  loading: false,
  detail: null,
  data: [],
  pagination: defaultPaging,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.ORDER_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case c.ORDER_FULFILLED:
      return {
        ...state,
        loading: false,
        detail: action.payload,
      };
    case c.ORDERS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        pagination: action.pagination,
      };
    default:
      return state;
  }
};

export default orderReducer;
