import * as c from './constants';

export const initialState = {
  loading: false,
  detail: null,
  options: [],
  banks: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.USER_LOADING:
      return {
        ...state,
        detail: null,
        loading: action.loading,
      };
    case c.USER_FULFILLED:
      return {
        ...state,
        loading: false,
        detail: action.payload,
      };
    case c.USER_LOGOUT:
      return {
        ...state,
        loading: false,
        detail: null,
      };
    case c.APP_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case c.BANKS:
      return {
        ...state,
        banks: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
