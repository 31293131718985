import * as c from './constants';
import api from './../../../utils/api';

export const fetchCouriers = () => {
  return async (dispatch) => {
    dispatch({
      type: c.COURIER_LOADING,
      loading: true,
    });

    try {
      const { data: res } = await api.get('/courier');
      if (res.success) {
        dispatch({
          type: c.COURIERS_FULFILLED,
          payload: res.data,
        });
      } else {
        dispatch({ type: c.COURIER_LOADING, loading: false });
      }
    } catch (error) {
      dispatch({ type: c.COURIER_LOADING, loading: false });
    }
  };
};