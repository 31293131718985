export const objectToQueryString = (params) => {
  return Object.keys(params)
    .filter((x) => params[x])
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const queryStringToObject = (queryString) => {
  const qs = new URLSearchParams(queryString);
  return qs;
};
