import React from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash/get';
import { useToasts } from 'react-toast-notifications';

import { Modal, Form } from '../../components';
import { actions } from '../../modules/reducers';

import {
  ModalContainer,
  ModalWrapper,
  ModalTitle,
  AddressCard,
  AddressContent,
  AddressHeader,
  AddressFooter,
} from './helpers';

const ManageAddress = ({
  dispatch,
  addAddress,
  changeAddress,
  onChange,
  onClose,
  address,
}) => {
  const { addToast } = useToasts();
  const { register, errors, handleSubmit } = useForm();

  React.useEffect(() => {
    register('address', { required: true });
  }, [register]);

  const onSubmit = (data) => {
    actions.addAddressWithoutDispatch(data, (err, res) => {
      if (err) {
        addToast(err, {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        dispatch(actions.fetchAddresses());
        setTimeout(() => {
          onChange(res);
        }, 400);
      }
    });
  };

  return (
    <React.Fragment>
      {addAddress ? (
        <Modal>
          <ModalContainer>
            <ModalWrapper>
              <ModalTitle>
                Tambah Alamat
                <button onClick={onClose}>
                  <i className="fa fa-close" />
                </button>
              </ModalTitle>
              <div style={{ padding: 16 }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Form.FormGroup>
                    <Form.Label>Nama</Form.Label>
                    <Form.Input
                      type="text"
                      name="name"
                      placeholder="Contoh: Toko Cabang Semarang"
                      ref={register({ required: true })}
                    />
                    {errors.name ? (
                      <Form.HelperText error>
                        Nama alamat tidak boleh kosong
                      </Form.HelperText>
                    ) : null}
                  </Form.FormGroup>
                  <Form.FormGroup>
                    <Form.Label>Nama Penerima</Form.Label>
                    <Form.Input
                      type="text"
                      name="receiver"
                      ref={register({ required: true })}
                    />
                    {errors.receiver ? (
                      <Form.HelperText error>
                        Nama penerima tidak boleh kosong
                      </Form.HelperText>
                    ) : null}
                  </Form.FormGroup>
                  <Form.FormGroup>
                    <Form.Label>No Hp Penerima</Form.Label>
                    <Form.Input
                      type="text"
                      name="phone"
                      ref={register({ required: true })}
                    />
                    {errors.phone ? (
                      <Form.HelperText error>
                        No handphone tidak boleh kosong
                      </Form.HelperText>
                    ) : null}
                  </Form.FormGroup>
                  <Form.FormGroup>
                    <Form.Label>Alamat</Form.Label>
                    <Form.TextArea
                      ref={register({ required: true })}
                      name="address"
                      placeholder="Contoh: Jl. Raya Pasar Minggu No 1"
                    />
                    {errors.address ? (
                      <Form.HelperText error>
                        Alamat tidak boleh kosong
                      </Form.HelperText>
                    ) : null}
                  </Form.FormGroup>

                  <Form.PrimaryButton type="submit">
                    Simpan dan Gunakan Alamat
                  </Form.PrimaryButton>
                </form>
              </div>
            </ModalWrapper>
          </ModalContainer>
        </Modal>
      ) : null}

      {changeAddress ? (
        <Modal>
          <ModalContainer>
            <ModalWrapper>
              <ModalTitle>
                Pilih Alamat
                <button onClick={onClose}>
                  <i className="fa fa-close" />
                </button>
              </ModalTitle>
              <div
                style={{
                  padding: 16,
                  overflow: 'auto',
                  height: 'calc(100vh - 50px)',
                }}
              >
                {address.map((addr) => {
                  return (
                    <AddressCard key={addr.id}>
                      <AddressHeader>
                        <span>{addr.name}</span>
                        {addr.set_default ? <small>Alamat Utama</small> : null}
                      </AddressHeader>
                      <AddressContent>
                        <span>{addr.receiver}</span>
                        <span>{addr.phone}</span>
                        <span style={{ fontSize: 11 }}>
                          {addr.address}, {get(addr, 'village', '')},{' '}
                          {get(addr, 'district', '')},{' '}
                          {get(addr, 'regency', '')},{' '}
                          {get(addr, 'province', '')} {addr.postal_code}
                        </span>
                      </AddressContent>
                      <AddressFooter>
                        <button onClick={() => onChange(addr)}>
                          Gunakan Alamat Ini
                        </button>
                      </AddressFooter>
                    </AddressCard>
                  );
                })}
              </div>
            </ModalWrapper>
          </ModalContainer>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default ManageAddress;
