import * as c from './constants';
import api from './../../../utils/api';

const loading = (section) => {
  return {
    type: c.HOME_LOADING,
    section,
  };
};

export const fetchHomeSection = (section) => {
  return async (dispatch) => {
    dispatch(loading(section));
    try {
      const sect = section === 'categories' ? `${section}?homepage=1` : section;
      const { data: res } = await api.get(`/home/${sect}`);
      dispatch({
        type: c.HOME_FULFILLED,
        section,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      // Handle error later
      // Show global popup
    }
  };
};
