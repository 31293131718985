import * as c from './constants';

export const initialState = {
  loading: false,
  detail: null,
  data: [],
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.ADDRESS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case c.ADDRESSES_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case c.ADDRESS_FULFILLED:
      return {
        ...state,
        loading: false,
        detail: action.payload,
      };
    default:
      return state;
  }
};

export default addressReducer;
