import React from 'react';
import { connect } from 'react-redux';
import s from 'styled-components';

import ModelHorizontal from './components/ModelHorizontal';

import { actions } from '../../modules/reducers';

const NoResult = s.div`
  padding-top: 50px;
  font-size: 14px;
  text-align: center;
`;

function Discount({ dispatch, loading, data }) {
  React.useEffect(() => {
    dispatch(actions.fetchHomeSection('discounts'));
  }, [dispatch]);

  return (
    <div style={{ margin: '56px auto 80px', maxWidth: 550 }}>
      {(data.discounts || []).length === 0 ? (
        <NoResult>Belum ada produk diskon. Kunjungi lagi nanti.</NoResult>
      ) : null}
      <ModelHorizontal
        loading={loading.discounts}
        data={data.discounts || []}
      />
    </div>
  );
}

export default connect(({ home }) => {
  return {
    loading: home.loading,
    data: home.data,
  };
})(Discount);
