import React from 'react';
import { connect } from 'react-redux';

import Slider from './components/Slider';
import Model from './components/Model';
import ModelHorizontal from './components/ModelHorizontal';

import { actions } from '../../modules/reducers';

function Home({ dispatch, loading, data }) {
  React.useEffect(() => {
    dispatch(actions.fetchHomeSection('slides'));
    dispatch(actions.fetchHomeSection('categories'));
    dispatch(actions.fetchHomeSection('feeds'));
  }, [dispatch]);

  return (
    <div style={{ margin: '56px auto 80px', maxWidth: 550 }}>
      <Slider loading={loading.slides} data={data.slides} />
      <Model loading={loading.categories} data={data.categories} />
      <ModelHorizontal loading={loading.feeds} data={data.feeds} />
    </div>
  );
}

export default connect(({ home }) => {
  return {
    loading: home.loading,
    data: home.data,
  };
})(Home);
