import React from 'react';

import {
  ImageWrapper,
  HeaderWrapper,
  Title,
  Loader,
  Description,
  ContentWrapper,
  ProductCount,
} from './helpers';

const Header = (props) => {
  return (
    <HeaderWrapper>
      <ImageWrapper>
        <img src={props.picture} alt={props.name} />
      </ImageWrapper>
      <ContentWrapper>
        {!props.loading ? <Title>{props.name}</Title> : <Loader />}
        {!props.loading ? (
          <Description>{props.description}</Description>
        ) : (
          <Loader />
        )}
        {!props.loading ? (
          <ProductCount>{props.count} motif</ProductCount>
        ) : null}
      </ContentWrapper>
    </HeaderWrapper>
  );
};

export default Header;
