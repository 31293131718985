import React from 'react';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';

import {
  Wrapper,
  Figure,
  Meta,
  Name,
  Price,
  Category,
  Discount,
} from './helpers';
import { countQuantity } from '../../utils/number';
import { getImage } from '../../utils/image';

const Card = (props) => {
  return (
    <Wrapper
      firstItem={props.firstItem}
      carousel={props.carousel}
      isLoading={props.isLoading}
    >
      {!props.isLoading ? (
        <React.Fragment>
          <Figure>
            {props.on_sale ? <Discount>{props.sale_price}%</Discount> : null}
            <Link to={`/produk/${props.slug}`}>
              <img src={getImage(props.picture, 200, '3:4')} alt={props.name} />
            </Link>
          </Figure>
          <Meta>
            <Name>
              <Link to={`/produk/${props.slug}`}>
                <Truncate lines={1} ellipsis="...">
                  {props.name}
                </Truncate>
              </Link>
            </Name>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.price !== undefined ? (
                <Price isOnSale={props.on_sale}>
                  Rp. {props.price.toLocaleString('id')}
                </Price>
              ) : null}
              {props.on_sale ? (
                <Price salePrice>
                  Rp.{' '}
                  {(
                    props.price -
                    (props.price * props.sale_price) / 100
                  ).toLocaleString('id')}
                </Price>
              ) : null}
            </div>
            {/* <>
              {props.sold_count !== undefined ? (
                <Category>
                  Terjual: {intToString(props.sold_count)} pcs
                </Category>
              ) : null}
            </> */}
            {props.count !== undefined ? (
              <Category>Stok: {countQuantity(props.count)}</Category>
            ) : null}
          </Meta>
        </React.Fragment>
      ) : null}
    </Wrapper>
  );
};

export default Card;
