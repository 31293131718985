import React from 'react';

import { Wrapper, Slider, Items } from './helpers';

const Carousel = (props) => {
  return (
    <Wrapper>
      <Slider>
        <Items>{props.children}</Items>
      </Slider>
    </Wrapper>
  );
};

export default Carousel;
