import * as c from './constants';

export const initialState = {
  loading: {
    slides: false,
    categories: false,
    feeds: false,
  },
  data: {
    slides: [],
    categories: [],
    feeds: [],
  },
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.HOME_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: true,
        },
      };
    case c.HOME_FULFILLED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: false,
        },
        data: {
          ...state.data,
          [action.section]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default homeReducer;
