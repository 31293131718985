import * as c from './constants';

const defaultQuery = new URLSearchParams(window.location.search);

const defaultPaging = {
  page: 1,
  pageSize: 10,
  rowCount: 1,
  pageCount: 1,
  has_next: true,
};

export const initialState = {
  loading: {
    explore: false,
    category: false,
    productCategory: false,
    product: false,
    relatedProducts: false,
    categoryRelatedProducts: false,
  },
  search: {
    query: defaultQuery.get('q') || '',
    page: defaultQuery.get('page') || 1,
  },
  data: {
    explore: [],
    category: [],
    productCategory: [],
    relatedProducts: [],
    categoryRelatedProducts: [],
  },
  detail: {
    category: null,
    product: null,
  },
  pagination: {
    explore: defaultPaging,
    category: defaultPaging,
    productCategory: defaultPaging,
  },
};

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.EXPLORE_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: true,
        },
      };

    case c.EXPLORE_FULFILLED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: false,
        },
        data: {
          ...state.data,
          [action.section]: state.data[action.section].concat(action.payload),
        },
        pagination: {
          ...state.pagination,
          [action.section]: action.pagination,
        },
      };

    case c.EXPLORE_DETAIL_FULFILLED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: false,
        },
        detail: {
          ...state.detail,
          [action.section]: action.payload,
        },
      };

    case c.EXPLORE_EMPTY_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.section]: [],
        },
      };

    case c.EXPLORE_RESET_SEARCH:
      return {
        ...state,
        search: {
          query: '',
          page: 1,
        },
        pagination: initialState.pagination,
      };

    case c.EXPLORE_SEARCH:
      return {
        ...state,
        search: {
          query: action.query,
          page: action.page,
        },
        loading: {
          ...state.loading,
          explore: true,
        },
      };

    case c.EXPLORE_RELATED_FULFILLED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: false,
        },
        data: {
          ...state.data,
          relatedProducts: action.payload,
        },
      };

    case c.EXPLORE_CATEGORY_RELATED_FULFILLED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.section]: false,
        },
        data: {
          ...state.data,
          categoryRelatedProducts: action.payload,
        },
      };
    default:
      return state;
  }
};

export default exploreReducer;
