import React from 'react';
import { connect } from 'react-redux';
import { InfiniteScroll } from 'react-simple-infinite-scroll';
import s from 'styled-components';

import { ProductCard } from '../../components';
import { actions } from '../../modules/reducers';

const Container = s.div`
  margin: 68px auto 80px;
  padding: 0 16px;
  max-width: 550px;
  min-height: 100vh;
`;

const ProductWrapper = s.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const Keyword = s.div`
  font-size: 14px;
  margin: 16px 0;
`;

const NoResult = s.div`
  font-size: 14px;
  text-align: center;
`;

function Explore({ dispatch, loading, data, pagination, search }) {
  const loadMore = React.useCallback(() => {
    dispatch(
      actions.exploreSearch({ query: search.query, page: search.page + 1 })
    );
  }, [dispatch, search.query, search.page]);

  React.useEffect(() => {
    dispatch(
      actions.fetchExplore('explore', {
        name: search.query,
        page: search.page,
        limit: 10,
      })
    );
  }, [dispatch, search.page, search.query]);

  React.useEffect(() => {
    return () => {
      dispatch(actions.exploreReset(true));
    };
  }, [dispatch]);

  return (
    <Container>
      {search.query ? (
        <Keyword>
          Kata kunci: <strong>{search.query}</strong>
        </Keyword>
      ) : null}
      {!loading && data.length === 0 ? (
        <NoResult>Pencarian tidak ditemukan.</NoResult>
      ) : null}
      <InfiniteScroll
        throttle={300}
        isLoading={loading}
        hasMore={pagination.has_next}
        onLoadMore={loadMore}
      >
        <ProductWrapper>
          {data.map((product, idx) => {
            let pictures = [];
            try {
              pictures = JSON.parse(product.pictures);
            } catch (err) {}

            return (
              <div
                key={idx}
                style={{
                  width: '48%',
                  marginBottom: 16,
                  marginLeft: idx % 2 === 0 ? 0 : '1%',
                }}
              >
                <ProductCard
                  key={product.id}
                  name={product.name}
                  price={product.price}
                  picture={pictures[0]}
                  slug={product.slug}
                  sale_price={product.sale_price}
                  on_sale={product.on_sale}
                  count={product.stock_qty}
                  sold_count={product.sold_count}
                />
              </div>
            );
          })}
          {loading
            ? [...Array(10).keys()].map((i, idx) => (
                <div
                  key={i}
                  style={{
                    width: '48%',
                    marginBottom: 16,
                    marginLeft: idx % 2 === 0 ? 0 : '1%',
                  }}
                >
                  <ProductCard isLoading />
                </div>
              ))
            : null}
        </ProductWrapper>
      </InfiniteScroll>
    </Container>
  );
}

export default connect(({ explore }) => {
  return {
    loading: explore.loading.explore,
    data: explore.data.explore,
    pagination: explore.pagination.explore,
    search: explore.search,
  };
})(Explore);
