import { Link } from 'react-router-dom';

import s from 'styled-components';

export const Container = s.section`
    width: 100%;
    background: #FFF;
    border-bottom: 1px solid #EEE;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    padding: 4px 0;
`;

export const Wrapper = s.div`
    max-width: 550px;
    margin: 0 auto;
`;

export const HomeOuter = s.div`
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
`;

export const HomeSearch = s.div`
    flex: 1;
    background: #EEE;
    border: 1px solid #DDD;
    margin: 4px 0;
    padding: 5px 0 6px 8px;
    border-radius: 4px;

    a {
        text-decoration: none;
        display: block;
        font-size: 13px;
        color: #666;
    }
`;

export const NormalOuter = s.div`
    position: relative;
    padding: 8px 0;
    display: flex;
`;

export const NormalTitle = s.div`
    flex: 1;
    ${(props) => (!props.backButton ? 'padding-left: 16px;' : '')}

    h2 {
        font-size: 16px;
        margin: 0;
    }
`;

export const AuthButton = s.div`
    display: inline-block;
    margin: 4px 0 0 8px;
`;

export const Button = s(Link)`
    text-decoration: none;
    color: #666;
    font-size: 22px;
    vertical-align: middle;
    margin: 0 0 0 8px;
    position: relative;

    .cart-count {
        position: absolute;
        text-align: center;
        background: red;
        display: inline-block;
        font-size: 10px;
        color: white;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-left: -5px;
    }
`;

export const BackButton = s.button`
    border: none;
    background: #FFF;
    padding: 0 16px;
    margin-top: 2px;
    
    > i {
        font-size: 20px;
    }
`;

export const SearchWrapper = s.div`
    padding: 0 16px;
    flex: 1;

    > form {
        display: flex;
        flex-flow: row wrap;
    }
`;

export const InputSearch = s.input`
    background: #EEE;
    border: none;
    height: 30px;
    flex: 1;
    border-radius: 4px 0 0 4px;
    padding-left: 8px;
`;

export const SearchReset = s.button`
    font-size: 11px;
    text-transform: uppercase;
    padding: 5px 8px;
    text-align: center;
    background: #EEE;
    border: none;
    border-radius: 0 4px 4px 0;
`;

export const SearchButton = s.button`
    width: 60px;
    font-size: 11px;
    text-transform: uppercase;
    padding: 5px 8px;
    text-align: center;
    background: darkred;
    color: #EEE;
    margin-left: 8px;
    border: none;
    border-radius: 4px;
`;
