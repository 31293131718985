import s from 'styled-components';

export const Wrapper = s.div`
    margin: 48px auto 0;
    padding: 16px;
    max-width: 550px;
`;

export const FormGroup = s.div`
    margin-bottom: 1rem;
    flex: 1;
`;

export const Label = s.label`
    display: inline-block;
    margin-bottom: 2px;
    font-size: 13px;
`;

export const Input = s.input`
    padding: 10px;
    border: 1px solid #ddd;
    display: block;
    width: 100%;
    border-radius: 3px;
`;

export const TextArea = s.textarea`
    padding: 10px;
    border: 1px solid #ddd;
    display: block;
    width: 100%;
    border-radius: 3px;
`;

export const PrimaryButton = s.button`
    padding: 12px 16px;
    width: 100%;
    display: block;
    background: darkred;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    margin-top: 16px;
`;

export const HelperText = s.p`
    font-size: 12px;
    color: ${(props) => (props.error ? 'red' : '#666')};
    margin: 8px 0;
`;
