import React from 'react';
import { Link } from 'react-router-dom';
import s from 'styled-components';

import { Carousel, ProductCard } from '../../../components';

// dummy data
import { models } from './../../../utils/dummy';

const Header = s.h3`
  position: relative;
  margin: 0 16px 16px;
  font-size: 14px;
  text-transform: uppercase;

  small {
    position: absolute;
    top: 0;
    right: 0;

    a {
      color: #111;
      text-decoration: none;
      font-weight: 400;
    }
  }
`;

const HeaderLoader = s.div`
  margin: 0 0 16px 16px;
  height: 19px;
  width: 50px;
  background: #DDD;
`;

function ModelHorizontal({ loading, data }) {
  return (
    <div>
      {loading
        ? models.map((model, mIdx) => {
            return (
              <div key={mIdx} style={{ marginBottom: 16 }}>
                <HeaderLoader />
                <Carousel>
                  {model.items.map((i, idx) => {
                    return (
                      <ProductCard
                        key={i}
                        isLoading
                        carousel
                        firstItem={idx === 0}
                      />
                    );
                  })}
                </Carousel>
              </div>
            );
          })
        : null}
      {data.map((cat) => {
        return (
          <div key={cat.id} style={{ marginBottom: 16 }}>
            <Header>
              {cat.name}
              <small>
                <Link to={`/kategori/${cat.slug}`}>Lihat semua</Link>
              </small>
            </Header>
            <Carousel>
              {(cat.products || []).map((product, idx) => {
                let pictures = [];
                try {
                  pictures = JSON.parse(product.pictures);
                } catch (err) {}

                return (
                  <ProductCard
                    key={product.id}
                    carousel
                    firstItem={idx === 0}
                    name={product.name}
                    price={product.price}
                    picture={pictures[0]}
                    sale_price={product.sale_price}
                    on_sale={product.on_sale}
                    slug={product.slug}
                    count={product.stock_qty}
                    sold_count={product.sold_count}
                  />
                );
              })}
            </Carousel>
          </div>
        );
      })}
    </div>
  );
}

export default ModelHorizontal;
