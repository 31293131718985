import { combineReducers } from 'redux';

import user from './user';
import home from './home';
import explore from './explore';
import address from './address';
import cart from './cart';
import order from './order';
import courier from './courier';

const rootReducer = combineReducers({
  user: user.reducer,
  home: home.reducer,
  explore: explore.reducer,
  address: address.reducer,
  cart: cart.reducer,
  order: order.reducer,
  courier: courier.reducer,
});

export const actions = {
  ...user.actions,
  ...home.actions,
  ...explore.actions,
  ...address.actions,
  ...cart.actions,
  ...order.actions,
  ...courier.actions,
};

export default rootReducer;
