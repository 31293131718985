import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import {
  Wrapper,
  FormGroup,
  Label,
  Input,
  PrimaryButton,
  HelperText,
} from './components/helpers';
import { actions } from '../../../modules/reducers';

function Profile({ history, dispatch, data }) {
  const { register, errors, handleSubmit } = useForm();
  const { addToast } = useToasts();

  React.useEffect(() => {
    dispatch(actions.fetchProfile());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(actions.updateProfile(data, { toast: addToast }));
  };
  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="name">Nama</Label>
          <Input
            type="text"
            name="name"
            defaultValue={data && data.name}
            ref={register({ required: true })}
          />
          {errors.name ? (
            <HelperText error>Nama tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="name">No Handphone</Label>
          <Input
            type="text"
            name="phone"
            defaultValue={data && data.phone}
            ref={register({ required: true })}
          />
          {errors.phone ? (
            <HelperText error>No handphone tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            defaultValue={data && data.email}
            ref={register}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="name">Password</Label>
          <Input
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password ? (
            <HelperText error>Password tidak boleh kosong</HelperText>
          ) : null}
        </FormGroup>
        <PrimaryButton type="submit">Ubah</PrimaryButton>
      </form>
    </Wrapper>
  );
}

export default connect(({ user }) => {
  return {
    data: user.detail,
    loading: user.loading,
  };
})(Profile);
